/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import config from '../../config.json'
// import LinkedIn from 'react-linkedin-login'
import { LinkedInCallback, LinkedIn } from 'react-linkedin-login-oauth2';

import * as constants from '../../constants';
import { gapi } from 'gapi-script';
import fb from '../../images/cat/fb.png';
import linked from '../../images/cat/in.png';
import google from '../../images/cat/google.png';
import UserService from '../../services/UserService';
import { setCurrentComponent, setPlatForm, setSocialMediaData, setUser } from '../../actions';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import * as constClass from '../../constants';
export class SocialLoginSignUp extends Component {
	constructor(props) {
		super(props);
		this.state = { isAuthenticated: false, user: null, token: '', isHidden: true };
	}
	componentDidMount() {
		this.props.setIsSocialSignUp(false)
		gapi.load('client:auth2', this.start);
	}
	start = () => {
		gapi.client.init({
			clientId:config.GOOGLE_CLIENT_ID,
			scope: 'email',
		});
	};

	logout = () => {
		this.setState({ isAuthenticated: false, token: '', user: null });
	};
	onFailure = (error) => {
		console.log(error);
	};
	facebookResponse = (response) => {
		if(response && response.accessToken && response.name){
		const socialMediaData = response.profileObj;
		const socialData = {
			access_token:response.accessToken,
			name:response.name,
			email:response.email,
		}
		this.props.setPlatform(constants.SOCIAL_MEDIA.FACEBOOK);
		this.props.setSocialMediaData(socialData)
		this.signUpOrLogin(response);
		this.props.setIsSocialSignUp(true)
	}
	};

	googleResponse = (response) => {
		const socialMediaData = response.profileObj;
		const socialData = {
			access_token:response.accessToken,
			name:socialMediaData.name,
			email:socialMediaData.email,
		}
		this.props.setSocialMediaData(socialData)
		this.props.setPlatform(constants.SOCIAL_MEDIA.GOOGLE);
		this.signUpOrLogin(response);
		this.props.setIsSocialSignUp(true)

	};
	signUpOrLogin = (response) => {
		if (this.props.type === constants.TYPE.SIGNUP) {
			// this.props.socialSignUpContents(response);
			// eslint-disable-next-line no-unused-expressions
			response.accessToken ? this.props.setCurrentComponent(constants.SHOWCOMPONENT.SOCIALSIGNUP): "";
		} else {
			let access_token = response.accessToken;
			let reqData = {
				social_login: true,
			};
			UserService.getSocialMediaInfo(
				access_token,
				reqData,
				this.props.platform,
				(err, response) => {
					if (!err && response) {
						localStorage.setItem(
							'userToken',
							JSON.stringify(response.token)
						);
						this.handleCookie(JSON.stringify(response.token));
						localStorage.setItem(
							'user',
							JSON.stringify(response.user)
						);
						this.props.setUser(response.user);
						this.props.closeLoginModal(false);
					} else if(err.code=== 'ERR006') {
                        toast.error(err.message, {
                            autoClose: constClass.TOAST_TIMEOUT_MEDIUM,
                            theme:'colored'
                          });
                    }
				}
			);
		}
	};
	handleCookie = (token) => {
		const { cookies } = this.props;
		if (token) {
			cookies.set('userToken', token, constants.COOKIE_OPTIONS); // setting the cookie
			cookies.set("isShowErrorMsg", JSON.stringify(true), constants.COOKIE_OPTIONS); // setting the cookie
		}
	};
	handlePostMessage = (event) => {
		if (event.data.type === 'code') {
			const { code } = event.data;
			this.getUserCredentials(code);
		}
	};
	getCodeFromWindowURL = (url) => {
		const popupWindowURL = new URL(url);
		return popupWindowURL;
	};
	url = (api) => {
		const { clientId, redirectUrl, oauthUrl, scope, state } = api;
		return `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
	};
	showPopup = (api) => {
		const { name } = api;
		const oAuthUrl = this.url(api);
		const width = 450,
			height = 730,
			left = window.screen.width / 2 - width / 2,
			top = window.screen.height / 2 - height / 2;
		window.open(
			oAuthUrl,
			name,
			'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
				width +
				', height=' +
				height +
				', top=' +
				top +
				', left=' +
				left
		);
	};
	callbackLinkedIn({ code, redirectUri }) {
		console.log(code);
	}
	
	render() {
		return (
			<div className={` ${this.state.isHidden ? 'fb-hide flex-social-box' : 'flex-social-box'}`}>
			{!this.state.isHidden &&	<FacebookLogin
                appId ={config.FACEBOOK_APP_ID}
                xfbml='true'
                version='14.0'
                textButton="" 
				autoLoad={false}
                cssClass='social-icon social-fb-icon pointer'
                icon={ <img src={fb} alt="google" className="social-icon"></img>}
                fields="name,email,picture"
                callback={this.facebookResponse}
				disableMobileRedirect={true}
            />}
				<LinkedIn
					clientId={config.LINKEDIN_APP_ID}
					scope={['r_emailaddress', 'r_liteprofile']}
					redirectUri={process.env.REACT_APP_REDIRECT_LINKEDIN_URL}
					onSuccess={(code) => {
						this.callbackLinkedIn({ code });
					}}
					onError={(error) => {
						console.log(error);
					}}

				>
					{({ linkedInLogin }) => (
						<img
							onClick={linkedInLogin}
							src={linked}
							className="social-icon  pointer"
							alt='Sign in with Linked In'
							style={{ maxWidth: '180px', cursor: 'pointer' }}
						/>
					)}
				</LinkedIn>
				{/* <p><a href="https://www.facebook.com/v2.8/dialog/oauth?client_id=3264320013786957&redirect_uri=http://localhost:8848/api/users/facebook/callback&scope=email&ider=122">Visit W3Schools.com!</a></p> */}
				<GoogleLogin
					clientId={config.GOOGLE_CLIENT_ID}
					render={(renderProps) => (
						<img
							onClick={renderProps.onClick}
							disabled={renderProps.disabled}
							src={google}
							alt='facebook'
							className='social-icon pointer'
						></img>
					)}
					buttonText='Login'
					callback={this.googleResponse}
					onSuccess={this.googleResponse}
					onFailure={this.onFailure}
				/>
				{/* <img src={linked} alt="linkedIn" className="social-icon" onClick={()=>this.showPopup(LinkedInApi)} /> */}
				{/* <img src={google} alt="linkedIn" className="social-icon" onClick={()=>this.showPopup(GoogleApi)} />
            <img src={fb} alt="facebook" className="social-icon" onClick={this.FacebookLogin}></img> */}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.webSiteUser.user,
	platform: state.webSiteUser.platform,
	type: state.webSiteUser.type,
});
const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setPlatform: (platform) => dispatch(setPlatForm(platform)),
	setSocialMediaData: (data) => dispatch(setSocialMediaData(data)),
	setCurrentComponent: (data) => dispatch(setCurrentComponent(data)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withCookies(SocialLoginSignUp));
