const normaluser = (state = {}, action) => {
  let newState;
    switch (action.type) {
      case 'SET_USER':  
        newState = Object.assign({}, state);
        newState.user = action.user
        return newState;
      case 'SHOW_LOADER':
        newState = Object.assign({}, state);        
        newState.loader = action.visibility
        return newState;
      case 'SET_PLATFORM':
        newState = Object.assign({}, state);        
        newState.platform = action.platform
        return newState;
      case 'SET_TYPE':
        newState = Object.assign({}, state);        
        newState.type = action.loginSignup 
        return newState;
      case 'SET_SOCIAL_MEDIA_DATA':
        newState = Object.assign({}, state);        
        newState.socialMediaData = action.socialMediaData 
        return newState;
      case 'SET_CURRENT_COMPONENT':
        newState = Object.assign({}, state);        
        newState.currentComponent = action.currentComponent 
        return newState;
      case 'SET_SIGN_UP_USER':  
        newState = Object.assign({}, state);
        newState.signUpUser = action.signUpUser
        return newState;
      default:
        return state;
    }
  }
  export default normaluser;