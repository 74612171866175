import Ajax from 'robe-ajax'
import { toast } from 'react-toastify';
// import { Router, browserHistory } from 'react-router';
import * as constClass from '../constants';
let isErrorShowing = false;
class Request {
  // static baseUrl = 'http://127.0.0.1:8848/api'
  static baseUrl = process.env.REACT_APP_API_URL;
  static getHeader() {
    let userToken = JSON.parse(localStorage.getItem('userToken'));
    let headers = {
      'originname' : "https://online.eduracle.com",
      'authorization': (userToken) ? 'Bearer ' + userToken : ''
    }
    if (window.authData) {
      headers.authorization = window.authData.token
    }
    return headers;
  }

  static call(service, {token, data, method }, cb) {
    let reqData = data || {}
    let headers = Request.getHeader();
    let reqProps = {
      url: Request.baseUrl + service,
      method: method || 'post',
      headers: headers,
      data: (method.toLowerCase() === 'get') ? undefined : JSON.stringify({access_token:token, reqData: reqData }),
      processData: true,
      cache: true,
      'contentType': 'application/json',
      // 'OriginName' : "https://online.dev.eduracle.com",

    }
    if (service.includes('activities/summary-excel')) {
      reqProps.responseType = 'blob';
    }
    else {
      reqProps.dataType = 'JSON'
    }
    return Ajax.ajax(reqProps).done((data, status, xhr) => {
      let err, errCode;
      isErrorShowing = false;
      if (reqProps.responseType && reqProps.responseType === 'blob') {
        cb(null, data)
      }
      else if (data && data.result.status) {
        if (data.responseData !== undefined && data.responseData !== null)
          cb(null, data.responseData)
        else
          cb(null, data.result)
      } else if (data && data.result.status === false) {
        err = data.errors[0].message;
        errCode = data.errors[0].code
        cb(data.errors[0])
      } else {
        err = 'Network error';
        cb({ code: 0, message: err })
      }

      if (err) {
        if (errCode === 'ERR419' || errCode === 'ERR013' || errCode === 'ERR401') {
          if (!isErrorShowing) {
            isErrorShowing = true;
            console.log('clearing');
            localStorage.clear();
            // browserHistory.push('/auth/login');
            toast.error(err, {
              autoClose: constClass.TOAST_TIMEOUT_MEDIUM,
              theme:'colored',
              onClose: () => {
                isErrorShowing = false;
               
              }
            });
          }

        }
        else if (errCode === 'ERR402' || errCode === 'ERR021') {
          //dont show toast for these error
          //handle in else block of request callback
        }
        else {
          if (!isErrorShowing) {
            isErrorShowing = true;
            toast.error(err, {
              autoClose: constClass.TOAST_TIMEOUT_MEDIUM,
              theme:'colored',
              onClose: () => {
                isErrorShowing = false;
              }
            });
          }
        }
        cb(err);
      }
    }).fail((data) => {
      let errCode, message;
      isErrorShowing = false;
      if (data.responseJSON&&data.responseJSON.errors) {
        errCode = data.responseJSON.errors[0].code;
        message = data.responseJSON.errors[0].message;
      }
      if (errCode === 'ERR419' || errCode === 'ERR013' || errCode === 'ERR401') {
        if (!isErrorShowing) {
          isErrorShowing = true;
            console.log('clearing');
            localStorage.clear();
          // browserHistory.push('/auth/login');
          toast.error(message, {
            autoClose: constClass.TOAST_TIMEOUT_MEDIUM,
              theme:'colored',
              onClose: () => {
              isErrorShowing = false;
              
            }
          });

          cb(data.responseJSON.errors);

        }
        cb(data.responseJSON.errors);

      } else if (errCode === 'ERR0130' || errCode === 'ERR006' || errCode === 'ERR021') {
        cb({ code:errCode, message: message })

      } else if (!isErrorShowing) {
        isErrorShowing = true;
        toast.error(message||'Network error', {
          autoClose: constClass.TOAST_TIMEOUT_MEDIUM,
              theme:'colored',
              onClose: () => {
            isErrorShowing = false;
          }
        });
        cb({ code: errCode || 0, message: message||'Network error' })
      }
    })
  }

  static upload(service, data, cb) {
    let reqData = data;

    let headers = Request.getHeader();
    return Ajax.ajax({
      url: Request.baseUrl + service,
      method: 'post',
      headers: headers,
      data: reqData,
      processData: false,
      cache: false,
      'contentType': false,
      // dataType: 'JSON'
    }).done((data, status, xhr) => {
      if (data && data.result.status) {
        cb(null, data.responseData)
      } else if (data && data.result.status === false) {
        cb(data.errors[0])
      } else {
        cb({ code: 0, message: 'Network error' })
      }
    }).fail((data) => {
      if(data.responseJSON&&data.responseJSON.result)
      {
       if (data.responseJSON.result.status === false) {
          cb(data.responseJSON.errors[0])
        } else {
          cb({ code: 0, message: 'Network error' })
        }
      }
      else
      {
        cb({ code: 0, message: 'Network error' })
      }
      
    })
  }
}

export default Request;