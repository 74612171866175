/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Loading from "./Loader";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import fb from '../images/cat/fb.png';
import linked from '../images/cat/in.png';
import google from '../images/cat/google.png';
import * as constants from "../constants";
import UserService from "../services/UserService";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  SociaLoginSignUp from './auth/SocialLoginSignUp'
import  SocialLoginSignUp  from "./auth/SocialLoginSignUp";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { setCurrentComponent, setType, setUser, setSignUpUser } from "../actions";
import NotificationMessage from "./Toast/NotificationMessage";
import moment from 'moment';
import Select from 'react-select';
export class SignUp extends Component {
    constructor(props) {
        super(props);
        this[constants.SIGNUP_REFS.STUDENT_REF] = React.createRef();
        this[constants.SIGNUP_REFS.PARENT_REF] = React.createRef();
        this.state = {
          userName: this.props?.signUpUser?.userName,
          email: this.props?.signUpUser?.email,
          studentEmail: this.props?.signUpUser?.studentEmail,
          studentName: this.props?.signUpUser?.studentName,
          studentMobile: this.props?.signUpUser?.studentMobile,
          userEmail: this.props?.signUpUser?.userEmail,
          studentNameForParentSignUp: this.props?.signUpUser?.studentNameForParentSignUp,
          mobileNo: this.props?.signUpUser?.mobileNo,
          errors: {},
          centers: this.props?.signUpUser?.centers,
          batches: this.props?.signUpUser?.batches,
          selectedCenter: this.props?.signUpUser?.selectedCenter,
          selectedBatch: this.props?.signUpUser?.selectedBatch,
          userCode: null,
          studentCode: null,
          role_id: this.props?.signUpUser?.role_id || constants.ROLE_ID.STUDENT,
          parentCode: null, 
          isLoading:false         
        };
    }
    componentDidMount = () => {
        this.props.renderImage(constants.SHOWIMAGE.STUDENTSIGNUPIMAGE);
        this.props.setType(constants.TYPE.SIGNUP)
        this.props.setSignUpType(constants.SHOWCOMPONENT.STUDENTSIGNUP);
        this.getCenters();
        let signUpFormData = this.getFormData();
        if(Object.keys(signUpFormData).length !== 0){
            const now = moment();
            const check = moment(signUpFormData.timeout);
            let difference = moment.duration(now.diff(check));
            difference = difference.asMinutes();
            if(difference > 60 ){
                sessionStorage.removeItem('signUpFormData');
            }
        }
        
        this.setStateValues();
        // if(this.props.signUpUser && this.props.signUpUser.parentSignUp){
        //     this.setState({parentSignUp : true, studentSignUp : false})
        //     // this.refparentEmail?.current?.focus();
        // }else if(this.props.signUpUser && this.props.signUpUser.studentSignUp){
        //     // this.refStudentEmail?.current?.focus();
        //     this.setState({parentSignUp : false, studentSignUp : true})
        // }
        if(this.props && this.props.signUpRef){
            this[this.props.signUpRef]?.current?.focus();
        }

    }
    getFormData = () => {
        let signUpFormData = sessionStorage.getItem('signUpFormData') ? JSON.parse(sessionStorage.getItem('signUpFormData')) : {};
        return signUpFormData;
    }
    setStateValues = () => {
        let signUpFormData = this.getFormData();
        if(signUpFormData){
            if(signUpFormData.selectedCenter){
            this.getBatches(signUpFormData.selectedCenter);
            }
            this.setState({
                userName: signUpFormData.userName || "",
                studentName: signUpFormData.studentName || "",
                email: signUpFormData.email || "",
                studentEmail: signUpFormData.studentEmail || "",
                mobileNo: signUpFormData.mobileNo || "",
                studentMobile: signUpFormData.studentMobile || "",
                selectedCenter: signUpFormData.selectedCenter || null,
                selectedBatch: signUpFormData.selectedBatch || null,
                role_id: signUpFormData.role_id || constants.ROLE_ID.STUDENT,
            });
            this.setImage(signUpFormData.role_id);
            if((signUpFormData.role_id == constants.ROLE_ID.PARENT) && signUpFormData.studentEmail){
                this.getStudentByEmail(signUpFormData.studentEmail);
            }
        }
    }
    setSignUpFormData = (fieldName, fieldValue) =>{
        let signUpFormData = this.getFormData();
        signUpFormData[fieldName] = fieldValue;
        signUpFormData["timeout"] = moment();
        sessionStorage.setItem('signUpFormData', JSON.stringify(signUpFormData));

    }
    selectedCenter= (selectedCenter) => {
        this.setState({selectedCenter: selectedCenter});
        this.getBatches(selectedCenter);
        this.setSignUpFormData("selectedCenter",selectedCenter);
    }
    selectedBatch= (selectedBatch) => {
        this.setState({selectedBatch: selectedBatch});
        this.setSignUpFormData("selectedBatch",selectedBatch);

    }
    getCenters = () => {
        let me = this,
        reqData= {
            instituteId: constants.ONLINE_INSTITUTE_ID
        }
        UserService.getCenters(reqData, function (err, response) {
            if (!err && response) {
                let tmpArry = response.map(x => {
                    return { key: x.id, label: x.name, value: x.id }
                })
                me.setState({ centers: tmpArry});
            }
        });
    }
    getBatches = (selectedCenter) => {
        let me = this,
        reqData= {
            center_id: [selectedCenter.value]
        }
        UserService.getBatchesByCenters(reqData, function (err, response) {
            if (!err && response) {
                let tmpArry = response.list.map(x => {
                    return { key: x.id, label: x.name, value: x.id }
                })
                me.setState({ batches: tmpArry });
            }
        });
    }
    setImage=(value)=>{
        const imageRender =value==constants.ROLE_ID.PARENT?constants.SHOWIMAGE.PARENTSIGNUPIMAGE:constants.SHOWIMAGE.STUDENTSIGNUPIMAGE;
        this.props.renderImage(imageRender);
        const Component =value==constants.ROLE_ID.PARENT?constants.SHOWCOMPONENT.PARENTSIGNUP:constants.SHOWCOMPONENT.STUDENTSIGNUP;
        this.props.setSignUpType(Component);

    }
    getStudentByEmail = (email) => {
        let errors= {};
        let me = this,
        reqData= {
            email_id: email
        }
        UserService.getUserByEmail(reqData, function (err, response) {
            if (err) {
                errors["response"] = err.message;
                me.setState({ errors: errors });
                if (err.code==='ERR006') {
                    me.props.setIsNewStudent(true);
                    me.setState({studentName: ''});
                }

              }
            else {
                me.props.setIsNewStudent(false)

                me.setState({studentName: response.name});
            }
        });
    }
    isValidEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            return true;
        }
        return false;
    }
    validateEmail = (email) => {
        let errors = {};
        let formIsValid = true;
        if (!this.isValidEmail(email)) {
            errors["studentEmail"] = "Email is not valid";
            this.setState({ errors: errors });
            formIsValid = false;
          }
        if(email){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
    
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2 && (email.length - lastDotPos) < 5 )) {
                formIsValid = false;
            }
        }
        return formIsValid
    }
    onChangeValue = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
         }
         if(e.target.name === "studentEmail" && this.state.role_id==constants.ROLE_ID.PARENT) {
             this.setState({
                 studentEmail: e.target.value,
                 typingTimeout: setTimeout( () => {
                     if(this.validateEmail(e.target.value)){
                     this.getStudentByEmail(e.target.value);
                     }
                    }, 1000)
                });
            }
            else {

                this.setState({ [e.target.name]: e.target.value });
            }
            if (e.target.name === 'role_id' && e.target.value !== this.state.role_id) {
                
                this.setState({
                    userName:"",
                    mobileNo:"",
                    email:"",
                    studentEmail:"",
                    studentName:"",
                    studentMobile:"",
                    selectedCenter:"",
                    selectedBatch:"",
                    errors: {}
                });
                this.props.setIsNewStudent(false);
                sessionStorage.removeItem('signUpFormData');
                const Component =this.state.role_id ? e.target.value==constants.ROLE_ID.PARENT?constants.SHOWCOMPONENT.PARENTSIGNUP:constants.SHOWCOMPONENT.STUDENTSIGNUP:'';
                this.setImage(e.target.value);
                this.props.setSignUpType(Component);
            }
            this.setSignUpFormData(e.target.name,e.target.value);
        } 
        handleKeyDown(e){
            if (e.key === 'e' ||e.key === '-' ||e.key === '+' ||e.key === '.' ) {
              e.preventDefault();
            }
          }  
          preventSpace(e){
            if (e.key === " ") {
                e.preventDefault();
              }
          }  
          onPaste(e) {
            const str = e.clipboardData.getData('Text');
            const newStr = str.replace(/[e+-.]/g, '');
            if (str !== newStr) {
              e.preventDefault()
             }
           } 
    handleValidation = () => {
        if(this.state.parentSignUp){
            // this.getStudentByEmail();
        }
        const name = this.state.userName ? this.state.userName : '';
        const email = this.state.email;
        const studentEmail = this.state.studentEmail;
        const studentName = this.state.studentName;
        const studentMobile = this.state.studentMobile;

        const mobileNo = this.state.mobileNo ? this.state.mobileNo : '';
        const studentSignUp = this.state.role_id == constants.ROLE_ID.STUDENT;
        const parentSignUp = this.state.role_id == constants.ROLE_ID.PARENT;
        const selectedCenter = this.state.selectedCenter ? this.state.selectedCenter.value : '';
        const selectedBatch = this.state.selectedBatch ? this.state.selectedBatch.value : '';
        let errors = {};
        let formIsValid = true;

        if (!name.replace(/\s+/g, '')) {
          formIsValid = false;
          errors["name"] = "Name cannot be empty";
        }
        else if(name.replace(/\s+/g, '').length<3){
            formIsValid=false;
            errors["name"] = "Please Enter a Valid Name";
          }
        if (!email) {
          formIsValid = false;
          errors["email"] = "Email cannot be empty";
        }
            if(email && !this.validateEmail(email)){
                    formIsValid = false;
                    errors["email"] = "Email is not valid";
            }
        if(parentSignUp && !name.replace(/\s+/g, '')){
            formIsValid=false;
            errors["name"] = "Name cannot be empty";
        }
        else if(parentSignUp && name.replace(/\s+/g, '').length<3){
            formIsValid=false;
            errors["name"] = "Please Enter a Valid Name";
        }
        if (parentSignUp && !studentEmail) {
            formIsValid = false;
            errors["studentEmail"] = "Email cannot be empty";
          }
        else if(parentSignUp && studentEmail && !this.validateEmail(studentEmail)){
            formIsValid = false;
            errors["studentEmail"] = "Email is not valid";
        }
        if(!mobileNo) {
            formIsValid = false;
            errors["mobileNo"] = "Mobile number cannot be empty";
        }
        if(mobileNo.length < 10 || mobileNo.length > 10 || !(mobileNo.match('[0-9]{10}'))){
            formIsValid = false;
            errors["mobileNo"] = "Please enter a valid 10 digit mobile number";
        }

        if((studentSignUp || this.props.isNewStudent) && selectedCenter.length < 1) {
            formIsValid = false;
            errors["targetExam"] = "Please select a Target Exam";
        }
        if((studentSignUp || this.props.isNewStudent) && selectedBatch.length < 1) {
            formIsValid = false;
            errors["targetYear"] = "Please select a Target Year";
        }
        if (this.props.isNewStudent && !studentName.replace(/\s+/g, '')) {
            formIsValid = false;
            errors["studentName"] = "Please Enter Student Name";    
        }
        else if(this.props.isNewStudent && studentName.replace(/\s+/g, '').length<3){
            formIsValid = false;
            errors["studentName"] = "Please Enter a Valid Name";  
        }
        if (this.props.isNewStudent && !studentMobile) {
            formIsValid = false;
            errors["studentMobile"] = "Mobile number cannot be empty";
        }
        if (this.props.isNewStudent && studentMobile && (studentMobile.length<10 || studentMobile.length > 10)) {
            formIsValid = false;
            errors["studentMobile"] = "Please enter a valid 10 digit mobile number";
        }
       
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleSubmit = (event) => {
        event.preventDefault();
    }
    signUp = () => {
        if(this.handleValidation()){
            // if(this.state.studentSignUp){
                this.props.setUserEmail(this.state.email);
                this.props.setSignUpUser(this.state);

                this.props.setStudentsEmail(this.state.studentEmail)

            let me = this;
            me.setState({ isLoading: true });
            let errors= {};
            let reqData = {
                name: me.state.userName,
                email: me.state.email ,
                // email: me.state.parentSignUp ? me.state.studentEmail : null,
                phone: me.state.mobileNo,
                role_id:parseInt(me.state.role_id),
                // center_id: me.state.selectedCenter ? me.state.selectedCenter : [],
                // batch_id: me.state.selectedBatch ? me.state.selectedBatch : [],
            };
            if(me.state.role_id == constants.ROLE_ID.STUDENT){
                reqData.center_id = me.state.selectedCenter ? me.state.selectedCenter.value : [];
                reqData.batch_id = me.state.selectedBatch ? me.state.selectedBatch.value : [];
            }
            if(me.state.role_id == constants.ROLE_ID.PARENT){
                reqData.details = {email: me.state.studentEmail, name: me.state.studentName}
            }
            if (me.props.isNewStudent) {
                reqData.details.register_student_user = true;
                reqData.details.phone = me.state.studentMobile;
                reqData.details.role_id = constants.ROLE_ID.STUDENT;
                reqData.details.center_id = me.state.selectedCenter ? me.state.selectedCenter.value : [];
                reqData.details.batch_id = me.state.selectedBatch ? me.state.selectedBatch.value : [];
            }
            UserService.signUp(reqData, function (err, response) {
                if (err) {
                 me.setState({ isLoading: false });
                 me.state.role_id === constants.ROLE_ID.STUDENT ? errors["studentEmail"] = err.message : errors["parentEmail"] = err.message;
                  me.setState({ errors: errors });
                //   me.toast.error(err);
                if (err.code==='ERR0130') {
                    me.props.setSignUpUser(this.state);
                    toast.error(<NotificationMessage reqData={reqData}/>, {
                          autoClose: constants.TOAST_TIMEOUT_MEDIUM,
                          theme:'colored',
                          toastId:err.code
                          
                        });
                }
                }
                else if(!err && response){
                    me.setState({ isLoading: false });
                    me.props.setIsSignup(true)
                    me.props.setCurrentComponent(constants.SHOWCOMPONENT.ENTERCODE);
                    me.props.setSignUpType(me.state.role_id == constants.ROLE_ID.STUDENT ? constants.SHOWCOMPONENT.STUDENTSIGNUP : constants.SHOWCOMPONENT.PARENTSIGNUP);
                }
            });
        }
    }
  render() {
    return (
        <div className="reset-pass">
            <Form onSubmit={this.handleSubmit}>
                <div className="form-min-block-1 std-bold-radio">
                <div className="mb-3 inline-radio">
                    <Form.Check
                        inline
                        label="Student Signup"
                        name="role_id"
                        type="radio"
                        id="inline-radio"
                        value={constants.ROLE_ID.STUDENT}
                        checked={this.state.role_id == constants.ROLE_ID.STUDENT}
                        onChange={this.onChangeValue}
                    />
                    <Form.Check
                        inline
                        label="Parent Signup"
                        name="role_id"
                        type="radio"
                        id="inline-radio"
                        value={constants.ROLE_ID.PARENT}
                        checked={this.state.role_id == constants.ROLE_ID.PARENT}
                        onChange={this.onChangeValue}
                    />
                </div>
                    <Form.Group className="mb-3">
                        <Form.Label className="input-label">Name</Form.Label>
                        <Form.Control name="userName" value={this.state.userName} type="text" placeholder="Enter Name" onChange={this.onChangeValue} />
                        <div className="error-msg error-msg-10">{ this.state.errors['name']}</div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="input-label">Email ID</Form.Label>
                        <Form.Control ref={this[constants.SIGNUP_REFS.STUDENT_REF]} name="email" value={this.state.email} type="email" placeholder="Enter Email ID" onChange={this.onChangeValue} onKeyDown={this.preventSpace} />
                        <div className="error-msg error-msg-10">{this.state.errors['email']}</div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="input-label">Mobile No.</Form.Label>
                        <Form.Control name="mobileNo" pattern="[0-9]*" onPaste={this.onPaste} value={this.state.mobileNo} type="number" placeholder="Enter Mobile No." onChange={this.onChangeValue} onWheel={ event => event.target.blur() } onKeyDown={this.handleKeyDown}/>
                        <div className="error-msg error-msg-10">{this.state.errors['mobileNo']}</div>
                    </Form.Group>
                    {this.state.role_id == constants.ROLE_ID.PARENT && <Form.Group className="mb-3">
                        <Form.Label className="input-label">Student Email ID</Form.Label>
                        <Form.Control ref={this[constants.SIGNUP_REFS.PARENT_REF]} name="studentEmail" type="email" placeholder="Enter Email ID" onChange={this.onChangeValue} value={this.state.studentEmail} onKeyDown={this.preventSpace} />
                        <div className="error-msg error-msg-10">{this.state.errors['studentEmail']}</div>
                    </Form.Group>}
                    {this.props.isNewStudent && <div className="student-txt-email">
                        The Student email ID entered by you is not registered. Please provide the following information to register the above Email ID as a new student.
                    </div>}
                    {this.props.isNewStudent && <Form.Group className="mb-3">
                        <Form.Label className="input-label">Student Name</Form.Label>
                        <Form.Control name="studentName" value={this.state.studentName} type="text" placeholder="Enter Name" onChange={this.onChangeValue} />
                        <div className="error-msg error-msg-10">{this.state.errors['studentName']}</div>
                    </Form.Group>}
                    {this.props.isNewStudent && <Form.Group className="mb-3">
                        <Form.Label className="input-label">Student Mobile No.</Form.Label>
                        <Form.Control name="studentMobile" pattern="[0-9]*" onPaste={this.onPaste} value={this.state.studentMobile} type="number" placeholder="Enter Mobile No." onChange={this.onChangeValue} onWheel={ event => event.target.blur() } onKeyDown={this.handleKeyDown} />
                        <div className="error-msg error-msg-10 ">{this.state.errors['studentMobile']}</div>
                    </Form.Group>}
                    {(this.state.role_id == constants.ROLE_ID.STUDENT || this.props.isNewStudent) && <div className="drop-down-outer">                  

            <Form.Group className="mb-3 width-100">        
            <Form.Label className="input-label">Target Exam</Form.Label>
            <Select
              name="targetExam"
              value={this.state.selectedCenter}
              placeholder="Target Exam"
              onChange={this.selectedCenter}
              options={this.state.centers}
            />  
            <div className="error-msg error-msg-10">{this.state.errors['targetExam']}</div>
            </Form.Group>
            
            <Form.Group className="mb-3 width-100">        
            <Form.Label className="input-label">Target Year</Form.Label>
            <Select
              name="targetYear"
              value={this.state.selectedBatch}
              placeholder="Target Year"
              onChange={this.selectedBatch}
              options={this.state.batches}
            />
            <div className="error-msg error-msg-10">{this.state.errors['targetYear']}</div>
            </Form.Group>
            </div>}
            </div>                  
                <Form.Group className="mb-3">
                    <Button buttonStyle={this.state.isLoading ? "red-btn btn-full-size no-margin loading-btn-disable" : "red-btn btn-full-size no-margin loading-button"} loading={this.state.isLoading} type="button" onClick={this.signUp}>
                   {this.state.isLoading && <Loading/>}
                    {!this.state.isLoading && `SIGN UP`}
                    </Button>
                </Form.Group>
            </Form>
            <div className="or-login-text">OR Signup with</div>
            <div className="social-signup">
                <SocialLoginSignUp 
                setIsSocialSignUp={(isSocialSignUp)=>this.props.setIsSocialSignUp(isSocialSignUp)}
                closeLoginModal={(closeModal) => this.props.closeLoginModal(closeModal)}
                />
            </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
    user: state.webSiteUser.user,
    type: state.webSiteUser.type,
    signUpUser: state.webSiteUser.signUpUser
  })
  const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    setType: type => dispatch(setType(type)),
    setCurrentComponent: currentComponent => dispatch(setCurrentComponent(currentComponent)),
    setSignUpUser: signUpUser => dispatch(setSignUpUser(signUpUser))
  })
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(SignUp))