import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Loading from "./Loader";
import { Button } from "./Button";
import edit from '../images/cat/edit-icon.png'
import * as constants from "../constants";
import UserService from "../services/UserService";
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import { setCurrentComponent, setSignUpUser } from "../actions";
const TIME = 'time'
const TIME1 = 'time1';
export class ResendCode extends Component {
  constructor(props) {
    super(props);
    this.time = null;
    this.time1 = null;
    this.state = {
      code: "",
      secondCode: "",
      errors: {},
      isLoading:false,
      continue: true,
      time: {
        minutes: 0,
        seconds: 0,
      },
      time1: {
        minutes: 0,
        seconds: 0,
      },
      duration: 60 * 1000,
      role_ids: [],
    };
    this.interval = null;
  }
  componentDidMount() {
    this.startTimer('initialState');
  }

  resendCode = (emailId,timer1) => {
    // e.preventDefault();
    // this.setState({time: {minutes: 0, seconds: 0}})
    let errors = {};
    let reqData = {
      userId: emailId,
      email: this.props.userEmail,
      mode: 'email',
      website_user: true,
      forget_password: this.props.signUpType === constants.SHOWCOMPONENT.FORGOTPASSWORD,
      register_student_user: this.props.isNewStudent,
      role_ids: this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP ? [constants.ROLE_ID.PARENT] : [constants.ROLE_ID.STUDENT],
      parent_social_signup: this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP  ? this.props.isSocialSignUp: false
    };
    UserService.sendOtp(reqData,  (err, response) =>{
      if (err) {
        errors["response"] = err.message;
        this.setState({ errors: errors });
      }
      else {
        this.setState({otp: response});
        this.startTimer(timer1);
      }
    });

  }
  startTimer = (timer1) =>{
        if(timer1 === constants.ROLE_ID.PARENT){ 
          this.setState({startTime: Date.now()})
          this.run(constants.ROLE_ID.PARENT,Date.now(),TIME);
          this[TIME] = setInterval(() => this.run(constants.ROLE_ID.PARENT,this.state.startTime,TIME),500)
        }else if(timer1 === constants.ROLE_ID.STUDENT){
          this.setState({startTime1: Date.now()});          
          this.run(constants.ROLE_ID.STUDENT,Date.now(),TIME1);
          this[TIME1] = setInterval(() => this.run(constants.ROLE_ID.STUDENT,this.state.startTime1,TIME1),500)
        }else if(timer1 === 'initialState'){
          this.setState({startTime: Date.now()})
          this.setState({startTime1: Date.now()})         
          this.run(constants.ROLE_ID.PARENT,Date.now(),TIME);
          this.run(constants.ROLE_ID.STUDENT,Date.now(),TIME1);
          this[TIME] = setInterval(() => this.run(constants.ROLE_ID.PARENT,this.state.startTime,TIME),500)
          this[TIME1] = setInterval(() => this.run(constants.ROLE_ID.STUDENT,this.state.startTime1,TIME1),500)
        }
  }
  msToTime = (duration) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    minutes = minutes.toString().padStart(2, '0');
    seconds = seconds.toString().padStart(2, '0');
    return {
      minutes,
      seconds,
    };
  }
  run = (timer1, startTimerFlag, timeCounter) => {
    let remainingTime;
      remainingTime = Date.now() - startTimerFlag;
      remainingTime = this.state.duration - remainingTime;
      if (remainingTime < 0) {
        remainingTime = 0;
      }
      this.setState(() => ({[timeCounter]: this.msToTime(remainingTime)}));
      if (remainingTime === 0) {
        clearTimeout(this[timeCounter]);
        this[timeCounter] = null;
      }
  }
  onChangeValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleValidation = () => {
    let code = this.state.code.trim();
    let secondCode = this.state.secondCode.trim();
    let errors = {};
    let formIsValid = true;
    if (!this.props.isSocialSignUp && !code) {
      formIsValid = false;
      errors["code"] = " Code cannot be empty";
    }
    if (!secondCode && (this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP)) {
      formIsValid = false;
      errors["secondCode"] = "Code cannot be empty";
    }
    // if(!timer1 || ){
    //   formIsValid = false;
    //   this.setState({codeExpired: true});
    // }
    this.setState({ errors: errors });
    return formIsValid;
  }
  continue = (e) => {
    e.preventDefault();
      if (this.handleValidation()) {
      let errors= {};
        let reqData = {
        // userId: me.props.userEmail ? me.props.userEmail : me.props.studentEmail ? me.props.studentEmail : me.props.parentEmail,
        userId:  this.props.userEmail,
        // userId: this.state.signUpType === constants.SHOWCOMPONENT.STUDENTSIGNUP ? me.props.studentEmail : me.state.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP ? me.props.parentEmail : me.props.userEmail,
        otp:  this.state.code ,
        external:this.props.signUpType === constants.SHOWCOMPONENT.FORGOTPASSWORD,
        website_user:true,
        forget_password:this.props.signUpType === constants.SHOWCOMPONENT.FORGOTPASSWORD,
        register_student_user: this.props.isNewStudent,
        parent_social_signup: this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP ? this.props.isSocialSignUp: false
      };
    if(this.props.studentEmail){
        reqData.student_otp = this.state.secondCode
      }
      // if(this.props.signUpType === constants.SHOWCOMPONENT.FORGOTPASSWORD){
      //   reqData.otp = parseInt(this.state.userCode)
      // }
      let me = this
      UserService.verifyOtp(reqData, function (err, response) {
        // this.setState({
        //   isLoading: false
        // });
        me.setState({ isLoading: true });
        if (!err && response) {
          me.setState({ isLoading: false });
          me.props.setCurrentComponent(constants.SHOWCOMPONENT.CONTINUE)
          if(me.props.signUpType === constants.SHOWCOMPONENT.FORGOTPASSWORD){
            me.props.setToken(response.token)
          }else if(me.props.signUpType === constants.SHOWCOMPONENT.STUDENTSIGNUP || 
            me.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP || 
            me.props.signUpType === constants.SHOWCOMPONENT.SIGNUP){
            me.props.setToken("")
          }
          me.props.setSignUpUser(null);
        }
        else if(err){
          me.setState({ isLoading: false });
          errors["response"] = err.message;
          me.setState({ errors: errors });
          if(err.code==='ERR0122' || err.code==='ERR0123'){
            errors["code"] = err.message;
            me.setState({errors: errors});
          }
          else if(err.code==='ERR0135' || err.code==='ERR0136'){
            errors["secondCode"] = err.message;
            me.setState({errors: errors});
          }
        }
    });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
  }
  onOpen = (ref) => {
    if (this.props.isSocialSignUp) {
    this.props.setCurrentComponent(constants.SHOWCOMPONENT.SOCIALSIGNUP)
      
    }else{

      this.props.setCurrentComponent(constants.SHOWCOMPONENT.SIGNUP)
    }
    this.props.setSignUpRef(ref)
  }
  render() {
    return (
      <div className="reset-pass">
        <Form onSubmit={this.handleSubmit}>
            <div className="form-min-block">
              
                {this.props.signUpType === constants.SHOWCOMPONENT.FORGOTPASSWORD && <div className="cat-form-title">Reset Password</div>}
                {this.props.signUpType === constants.SHOWCOMPONENT.STUDENTSIGNUP && <div className="student-signup-outer">
                  <div className="cat-form-sub-title">{this.props.signUpType === constants.SHOWCOMPONENT.STUDENTSIGNUP && 'Please enter the One Time Verification Code sent on'}</div>
                  {this.props.userEmail && <div className="send-code-row">
                    <div className="cat-email-sub-title span-verification-code"><span className="email-img-span"><span className="email-span">{this.props.userEmail}</span>
                    <span><img src={edit} alt="edit" className="edit-img" onClick={() => this.onOpen(constants.SIGNUP_REFS.STUDENT_REF)}></img></span></span></div>
                  </div>}
                </div>}
                {!this.props.isSocialSignUp && this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP && <div>
                  <div className="cat-form-sub-title">{this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP && 'Please enter the One Time Verification Code sent on your registered email ID'}</div>
                  {this.props.userEmail && <div className="send-code-row"> 
                    <div className="cat-email-sub-title span-verification-code"><span className="padding-text">{this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP ? '(Parent ID) Verification Code for ' : ''}</span><span className="email-img-span"><span className="email-span"> {this.props.userEmail}</span>
                    <span><img src={edit} alt="edit" className="edit-img" onClick={() => this.onOpen(constants.SIGNUP_REFS.STUDENT_REF)}></img></span></span></div>
                  </div>}
                </div>}
                {!this.props.isSocialSignUp && this.props.userEmail &&<Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control name="code" type="text" onChange={this.onChangeValue} value={this.state.code}/>
                    <div className="error-msg">{this.state.errors['code']}</div>
                </Form.Group>} 
                {!this.props.isSocialSignUp &&this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP && <div className="timer-code-row">
                    <div className="dont-send">Didn't receive the code? <span><button className="resend-code"  disabled={(this.state.duration - (Date.now() - this.state.startTime))>1000} onClick={() => {this.resendCode(this.props.userEmail,constants.ROLE_ID.PARENT)}}>Resend</button></span></div>
                    <div className="timer">
                        {(this.state.duration - (Date.now() - this.state.startTime))>1000 && <div>{this.state.time.minutes}:{this.state.time.seconds}</div>}
                    </div>
                </div>}
                {this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP && <div className="send-code-row">
                    <div className="cat-email-sub-title span-verification-code"><span className="padding-text">{this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP ? '(Student ID) Verification Code for ' : ''}</span><span className="email-img-span"><span className="email-span">{this.props.studentEmail}</span><span>
                    <img src={edit} alt="edit" className="edit-img" onClick={() => this.onOpen(constants.SIGNUP_REFS.PARENT_REF)}></img></span></span></div>
                    
                </div>
                }
               
                {(this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP || this.props.isSocialSignUp ) &&  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control name="secondCode" type="text" onChange={this.onChangeValue} value={this.state.secondCode}/>
                    <div className="error-msg">{this.state.errors['secondCode']}</div>
                </Form.Group>}
                <div className="timer-code-row">
                    <div className="dont-send">Didn't receive the code? <span><button className="resend-code"  disabled={(this.state.duration - (Date.now() - this.state.startTime1))>1000} onClick={() => {this.resendCode(this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP ? this.props.studentEmail : this.props.userEmail,constants.ROLE_ID.STUDENT)}}>Resend</button></span></div>
                    <div className="timer">
                        {(this.state.duration - (Date.now() - this.state.startTime1))>1000 && <div>{this.state.time1.minutes}:{this.state.time1.seconds}</div>}
                    </div>
                </div>
            </div>
            <Form.Group className="mb-3">
                <Button buttonStyle={this.state.isLoading ? "red-btn btn-full-size mr-top-50 loading-btn-disable" : "red-btn btn-full-size mr-top-50 loading-button"} type="button" loading={this.state.isLoading} onClick={this.continue}>
                {this.state.isLoading && <Loading/>}
                {!this.state.isLoading && `CONTINUE`}
                </Button>
            </Form.Group>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.webSiteUser.user
})
const mapDispatchToProps = dispatch => ({
  setCurrentComponent: currentComponent => dispatch(setCurrentComponent(currentComponent)),
  setSignUpUser: signUpUser => dispatch(setSignUpUser(signUpUser))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendCode)