export const setUser = user => ({
    type: 'SET_USER',
    user
  })
export const showLoader = visibility => ({
    type: 'SHOW_LOADER',
    visibility
  })

export const setPlatForm = platform => ({
  type: 'SET_PLATFORM',
  platform
})

export const setType = loginSignup => ({
  type: 'SET_TYPE',
  loginSignup
})

export const setSocialMediaData = socialMediaData => ({
  type: 'SET_SOCIAL_MEDIA_DATA',
  socialMediaData
})
export const setCurrentComponent = currentComponent => ({
  type: 'SET_CURRENT_COMPONENT',
  currentComponent
})
export const setSignUpUser = signUpUser => ({
  type: 'SET_SIGN_UP_USER',
  signUpUser
})