import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

class Loading extends Component {
    render() {
        return (
            <Spinner
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"/>
        );
    }
}
export default Loading;