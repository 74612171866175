import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Loading from "./Loader";
import { Button } from "./Button";
import * as constants from "../constants";
import UserService from "../services/UserService";
import { connect } from "react-redux";
import { setCurrentComponent, setType, setUser } from '../actions/index';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import  SociaLoginSignUp from './auth/SocialLoginSignUp'

export class Login extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
    constructor(props) {
        super(props);
        this.state = {
          userName: "",
          password: "",
          isLoading: false,
          errors: {},
          keepLoggedIn: false
        };
    }

    handleCookie = (token) => {
      const { cookies } = this.props;
      if(token){
        cookies.set("userToken", token, constants.COOKIE_OPTIONS); // setting the cookie
        cookies.set("keepMeLoggedIn", JSON.stringify(this.state.keepLoggedIn), constants.COOKIE_OPTIONS); // setting the cookie
        cookies.set("isShowErrorMsg", JSON.stringify(true), constants.COOKIE_OPTIONS); // setting the cookie
        cookies.set("isLoggedIn", JSON.stringify(true), constants.COOKIE_OPTIONS);
      }
    }
    componentDidMount() {
      this.props.renderImage(constants.SHOWIMAGE.STUDENTLOGINIMAGE);
      this.props.setType(constants.TYPE.LOGIN)
    }
    onChangeValue = (e) => {
      e.target.name === "keepLoggedIn" ? this.setState({ [e.target.name]: e.target.checked }): this.setState({ [e.target.name]: e.target.value });
    }
    handleKeyDown(e){
      if (e.key === " ") {
        e.preventDefault();
      }
    }
    handleValidation = () => {
        const userName = this.state.userName;
        const password = this.state.password;
        const errors = {};
        let formIsValid = true;

        if (!password) {
          formIsValid = false;
          errors["password"] = "Password cannot be empty";
        } else if(this.props.validatePassword(password)){
          formIsValid = false;
          errors["password"] = "Spaces are not allowed";
        } else if (password.trim().length < 6) {
          formIsValid = false;
          errors["password"] = "Password length should be of minimum 6 characters";
        }

        if (!userName.replace(/\s+/g, '')) {
          formIsValid = false;
          errors["userName"] = "User name cannot be empty";
        }
    
        this.setState({ errors: errors });
        return formIsValid;
    }
    login = (e) => {
        e.preventDefault()
        let errors = {};
        let me = this,
          reqData = {
            username: this.state.userName,
            password: this.state.password,
            website: true,
            external:true,
            is_keep_logged_in: this.state.keepLoggedIn
          };

        if (this.handleValidation()) {
          me.setState({ isLoading: true });
          UserService.login(reqData, function (err, response) {
            if (err) {
              me.setState({ isLoading: false });
              errors["response"] = err.message;
              me.setState({ errors: errors });
            }
            else if(!err){
                me.setState({ isLoading: false });
                localStorage.setItem('userToken', JSON.stringify(response.token));
                me.userinfo(response)
                me.handleCookie(JSON.stringify(response.token));
                localStorage.setItem('keepMeLoggedIn', me.state.keepLoggedIn);
                sessionStorage.setItem('userLoggedIn', true);
        }
          });
        }
    }
    userinfo = (response)=>{
      UserService.me((err,res)=>{
        if (!err) {
          this.setState({ isLoading: false });
          localStorage.setItem('user', JSON.stringify(res));
          this.props.setUser(res);

          if (res.username) {
            
            this.props.closeLoginModal(false);
          }else{
            this.props.setUserEmail(res.email);
            this.props.setCurrentComponent(constants.SHOWCOMPONENT.CONTINUE)
            this.props.setSignUpType(constants.SHOWCOMPONENT.FORGOTPASSWORD);
          }
          this.props.saveActivity();
        }

      })
    }
  render() {
    return (
    <div className="reset-pass">
        <Form>
            <div className="form-min-block-1">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="input-label">Enter Username</Form.Label>
                    <Form.Control onChange={this.onChangeValue} name="userName" type="text" placeholder="Enter Username"/>
                    <div className="error-msg">{this.state.errors['userName']}</div>
                </Form.Group>
                <Form.Group className="mb-3 re-enter-pass" controlId="password">
                    <Form.Label className="input-label">Password</Form.Label>
                    <div className="input-icon">
                        <Form.Control name="password" type={this.state.viewPassword ? 'text' : 'password'} placeholder="Enter Password" onChange={this.onChangeValue} onKeyDown={this.handleKeyDown}/>
                        <div className="font-icon">
                            <i onClick={() => { this.setState({ viewPassword: !this.state.viewPassword }) }} className={this.state.viewPassword ? "fas fa-eye" : "fas fa-eye-slash"}/>
                        </div>
                    </div>
                    <div className="error-msg">{this.state.errors['password']}</div>
                </Form.Group>
                <div className="forgot-pass"><v-btn onClick={() => {this.props.setCurrentComponent(constants.SHOWCOMPONENT.FORGOTPASSWORD); this.props.setIsSignup(false)}}>Forgot Password?</v-btn></div>
                <Form.Group className="mb-3 keep-me" controlId="keeploggedin">
                    <Form.Check type="checkbox" label="Keep Me Logged In" name="keepLoggedIn" onChange={this.onChangeValue}/>
                </Form.Group>
            </div>
            <Form.Group className="mb-3">
                <Button buttonStyle={this.state.isLoading ? "red-btn btn-full-size no-margin loading-btn-disable" : "red-btn btn-full-size no-margin loading-button"} type="submit" loading={this.state.isLoading} onClick={this.login}>
                {this.state.isLoading && <Loading/>}
                {!this.state.isLoading && `LOGIN`}
                </Button>
            </Form.Group>
        </Form>
        <div className="or-login-text">OR Login with</div>
        <div className="social-signup">
          <SociaLoginSignUp
            closeLoginModal={(closeModal) => this.props.closeLoginModal(closeModal)}
            setIsSocialSignUp={(isSocialSignUp)=>this.props.setIsSocialSignUp(isSocialSignUp)}
            />
        </div>
    </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.webSiteUser.user,
  currentComponent: state.webSiteUser.currentComponent
})
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  setType: type => dispatch(setType(type)),
  setCurrentComponent: type => dispatch(setCurrentComponent(type))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Login))