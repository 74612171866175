import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux'

import store from './redux/index'
// eslint-dable-next-line no-undef
import { CookiesProvider } from "react-cookie";



ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter><CookiesProvider ><App /></CookiesProvider></BrowserRouter>
  </Provider>,
  document.getElementById("root")
);