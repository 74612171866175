import Request from './Request';
class UserService {
    static login(reqData, cb) {
        Request.call('/users/login', { data: reqData, method: 'post' }, cb);
    }
    static me(cb) {
        Request.call('/users/me', {  method: 'get' }, cb);
    }
    static signUp(reqData, cb) {
        Request.call('/users/website/create', { data: reqData, method: 'post' }, cb);
    }
    static forgotPassword(reqData, cb) {
        Request.call('/users/password/new', { data: reqData, method: 'post' }, cb);
    }
    static checkUserId(reqData, cb) {
        Request.call('/users/username/check', { data: reqData, method: 'post' }, cb);
    }
    static resetPassword(reqData, cb) {
        Request.call('/users/password/reset', { data: reqData, method: 'post' }, cb);
    }
    static logout(cb) {
        Request.call('/users/logout', { data: '', method: 'get' }, cb);
    }
    static getCenters(reqData, cb) {
        Request.call('/center/get-by-institute-id/'+reqData.instituteId, { method: 'get' }, cb);
    }
    static getBatchesByCenters(reqData, cb) {
        Request.call('/batch/get', { data: reqData, method: 'post' }, cb);
    }
    static sendOtp(reqData, cb) {
        Request.call('/users/website/resend-otp', { data: reqData, method: 'post' }, cb);
    }
    static verifyOtp(reqData, cb) {
        Request.call('/users/website/verify-otp', { data: reqData, method: 'post' }, cb);
    }
    static setPassword(reqData, cb) {
        Request.call('/users/website/password', { data: reqData, method: 'post' }, cb);
    } 
    static getPackages(reqData, cb) {
        Request.call('/product/get-online-subscription', { data: reqData, method: 'post' }, cb);
    }
    static getUserByEmail(reqData, cb) {
        Request.call('/users/get-user-by-email', { data: reqData, method: 'post' }, cb);
    }
    static contactUs(reqData, cb) {
        Request.call('/users/send-enquiry', { data: reqData, method: 'post' }, cb);
    }
    static getSocialMediaInfo(access_token,reqData,socialMedia,cb) {
        let url = '/users/signup/'+socialMedia;
        Request.call(url, { token:access_token ,data:reqData ,method: 'post' }, cb);
    }
    static saveActivities(reqData, cb) {
        Request.call('/activities/duration/save', { data: reqData, method: 'post' }, cb);
    }

    static getLinkedInInfo({socialMedia,code},cb) {
        let url = '/users/'+socialMedia+'/callback?code='+code;
        Request.call(url, {  method: 'get' }, cb);
    }
    static checkUserName(reqData,cb) {
        
        Request.call("/users/username/check", { data: reqData, method: 'post' }, cb);
    }
}
export default UserService;