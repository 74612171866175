export const SHOWCOMPONENT = {
    LOGIN: "LOGIN",
    SIGNUP: "SIGNUP",
    FORGOTPASSWORD: "FORGOTPASSWORD",
    ENTERCODE: "ENTERCODE",
    CONTINUE: "CONTINUE",
    SOCIALSIGNUP: "SOCIALSIGNUP",
    STUDENTSIGNUP:"STUDENTSIGNUP",
    PARENTSIGNUP:"PARENTSIGNUP"
}
export const MYT_REDIRERT_URL="https://myt.eduracle.com";
export const SHOWIMAGE = {
    STUDENTLOGINIMAGE:"STUDENTLOGINIMAGE",
    STUDENTSIGNUPIMAGE:"STUDENTSIGNUPIMAGE",
    PARENTSIGNUPIMAGE:"PARENTSIGNUPIMAGE",
    OTP: "OTP"
}
export const ROLE_ID = {
    STUDENT: 9,
    PARENT: 10,
}

export const ONLINE_INSTITUTE_ID = 37;

export const TOAST_TIMEOUT = 2000;
export const TOAST_TIMEOUT_MEDIUM = 1500;
export const TOAST_TIMEOUT_SHORT = 1000;

export const DATETIMEFORMAT = "YYYY-MM-DD HH:mm:ss";
export const ADAPTIVE_PRACTICE_SESSION_URL = "https://catv2-sol-videos.s3.ap-south-1.amazonaws.com/practice_session_video_v5.mp4";
export const MAKE_YOUR_OWN_TEST_URL = "https://catv2-sol-videos.s3.ap-south-1.amazonaws.com/make_your_own_test_v5.mp4";
export const CUTTING_EDGE_RESULT_ANALYSIS_URL = "https://catv2-sol-videos.s3.ap-south-1.amazonaws.com/data_analytics_v5.mp4";
export const PREVIOUS_YEAR_QUE_PAPERS_URL = "https://catv2-sol-videos.s3.ap-south-1.amazonaws.com/eduracle_product_explainer_video.mp4";
export const CET_JEE_NEET_PDF_URL="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Table_Of_Contents_JEE_NEET_AND_CET.pdf"; 
export const NEET="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Table_Of_Contents_NEET.pdf"; 
export const SAMPLE_NEET="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Sample_File_ NEET.pdf"; 
export const RNM_SAMPLE="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Sample_File_RMN.pdf"; 
export const KCET_SAMPLE="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Sample_File_KCET.pdf"; 
export const JEE_MAIN_ADV="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Table_Of_Contents_JEE_Main_And_Advanced.pdf"; 
export const SAMPLE_JEE_MAIN_ADV="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Sample_File_JEE_Main_And_Advanced.pdf"; 
export const DPP="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Table_of_Contents_DPPs.pdf"; 
export const FOUNDATION="https://catv2-images.s3.ap-south-1.amazonaws.com/documents/Table_of_Contents_Foundation.pdf"; 

export const COOKIE_OPTIONS = {
    path: '/',
    domain: '.eduracle.com'
}
export const SOCIAL_MEDIA = {
    GOOGLE: 'google',
    LINKEDIN: 'linked-in',
    FACEBOOK: 'facebook'
}
export const TYPE = {
    LOGIN: "LOGIN",
    SIGNUP: "SIGNUP"
}
export const SIGNUP_REFS = {
    STUDENT_REF : 'refStudentEmail', 
    PARENT_REF : 'refStudentEmail1'
}

export const ONE_YEAR_JEE_PLAN_ID=17;
export const ONE_YEAR_NEET_PLAN_ID=20;