/* eslint-disable no-dupe-class-members */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Loading from "./Loader";
import { Button } from './Button';
import * as constants from '../constants';
import UserService from '../services/UserService';
import { connect } from 'react-redux';
import { setCurrentComponent, setSignUpUser, setUser } from '../actions';
import { withCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import Select from 'react-select';

export class SocialSignUp extends Component {
	constructor(props) {
		super(props);
        this[constants.SIGNUP_REFS.PARENT_REF] = React.createRef();
		this.state = {
			name: this.props?.signUpUser?.name,
			studentEmail: this.props?.signUpUser?.studentEmail,
			studentName: this.props?.signUpUser?.studentName,
			studentMobile: this.props?.signUpUser?.studentMobile,
			mobileNo: this.props?.signUpUser?.mobileNo,
			isLoading:false,
			errors: {},
			signUpType: '',
			email: '',
			typingTimeout: 0,
			role_id: this.props.signUpType ===
					constants.SHOWCOMPONENT.PARENTSIGNUP
						? constants.ROLE_ID.PARENT
						: constants.ROLE_ID.STUDENT,
			selectedBatch:this.props?.signUpUser?.selectedBatch,
			selectedCenter: this.props?.signUpUser?.selectedCenter
		};
	}
	componentDidMount() {
		this.getCenters();
		this.setData();
		if(this.props && this.props.signUpRef){
            this[this.props.signUpRef]?.current?.focus();
        }
	}
	onChangeValue = (e) => {
		if (this.state.typingTimeout) {
			clearTimeout(this.state.typingTimeout);
		}
		if (e.target.name === 'studentEmail') {
			// this.setState({ studentEmail: e.target.value });
			this.setState({
				studentEmail: e.target.value,
				typingTimeout: setTimeout(() => {
                     if(this.validateEmail(e.target.value)){
                     this.getStudentByEmail(e.target.value);
                     }				
				}, 1000),
			});
		} else {
			this.setState({ [e.target.name]: e.target.value });
		}
	};

	getStudentByEmail = (email) => {
		let errors = {};
		let me = this,
			reqData = {
				email_id: email,
			};
			UserService.getUserByEmail(reqData, function (err, response) {
				if (err) {
					errors["response"] = err.message;
					me.setState({ errors: errors });
					if (err.code==='ERR006') {
						me.props.setIsNewStudent(true);
						me.setState({studentName: ''});
					}
	
				  }
				else {
					me.props.setIsNewStudent(false)
	
					me.setState({studentName: response.name});
				}
			});
	};
	getCenters = () => {
        let me = this,
        reqData= {
            instituteId: constants.ONLINE_INSTITUTE_ID
        }
        UserService.getCenters(reqData, function (err, response) {
            if (!err && response) {
                let tmpArry = response.map(x => {
                    return { key: x.id, label: x.name, value: x.id }
                })
                me.setState({ centers: tmpArry});
            }
        });
    }
    handleCookie = (token) => {
		const { cookies } = this.props;
		if (token) {
			cookies.set('userToken', token, constants.COOKIE_OPTIONS); // setting the cookie
		}
	};

	getBatches = (selectedCenter) => {
        let me = this,
        reqData= {
            center_id: [selectedCenter.value]
        }
        UserService.getBatchesByCenters(reqData, function (err, response) {
            if(err){
                toast.error(err.message);
            }
            else if (!err && response) {
                let tmpArry = response.list.map(x => {
                    return { key: x.id, label: x.name, value: x.id }
                })
                me.setState({ batches: tmpArry });
            }
        });
    }
	handleKeyDown(e){
		if (e.key === 'e' ||e.key === '-' ||e.key === '+' ||e.key === '.') {
		  e.preventDefault();
		}
	  } 
	  onPaste(e) {
		const str = e.clipboardData.getData('Text');
		const newStr = str.replace(/[e+-.]/g, '');
		if (str !== newStr) {
		  e.preventDefault()
		 }
	   }
	handleValidation = () => {
		const name = this.state.name ? this.state.name : '';
        const email = this.state.email;
        const studentEmail = this.state.studentEmail;
        const studentName = this.state.studentName;
        const studentMobile = this.state.studentMobile;

        const mobileNo = this.state.mobileNo ? this.state.mobileNo : '';
        const studentSignUp = this.props.signUpType === constants.SHOWCOMPONENT.STUDENTSIGNUP
        const parentSignUp = this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP
        const selectedCenter = this.state.selectedCenter ? this.state.selectedCenter.value : '';
        const selectedBatch = this.state.selectedBatch ? this.state.selectedBatch.value : '';
        let errors = {};
        let formIsValid = true;

        if (!name.replace(/\s+/g, '')) {
          formIsValid = false;
          errors["name"] = "Name cannot be empty";
        }
        else if(name.replace(/\s+/g, '').length<3){
            formIsValid=false;
            errors["name"] = "Please Enter a Valid Name";
          }
        if (!email) {
          formIsValid = false;
          errors["email"] = "Email cannot be empty";
        }
		if(email && !this.validateEmail(email)){
			formIsValid = false;
			errors["email"] = "Email is not valid";
		}
	
        if (parentSignUp && !studentEmail) {
            formIsValid = false;
            errors["studentEmail"] = "Email cannot be empty";
          }
        else if(parentSignUp && studentEmail && !this.validateEmail(studentEmail)){
            formIsValid = false;
            errors["studentEmail"] = "Email is not valid";
        }
        if(!mobileNo) {
            formIsValid = false;
            errors["mobileNo"] = "Mobile number cannot be empty";
        }
        if(mobileNo.length < 10 || mobileNo.length > 10 || !(mobileNo.match('[0-9]{10}'))){
            formIsValid = false;
            errors["mobileNo"] = "Please enter a valid 10 digit mobile number";
        }

        if((studentSignUp || this.props.isNewStudent) && selectedCenter.length < 1) {
            formIsValid = false;
            errors["targetExam"] = "Please select a Target Exam";
        }
        if((studentSignUp || this.props.isNewStudent) && selectedBatch.length < 1) {
            formIsValid = false;
            errors["targetYear"] = "Please select a Target Year";
        }
        if (this.props.isNewStudent && !studentName.replace(/\s+/g, '')) {
            formIsValid = false;
            errors["studentName"] = "Please Enter Student Name";    
        }
        else if(this.props.isNewStudent && studentName.replace(/\s+/g, '').length<3){
            formIsValid = false;
            errors["studentName"] = "Please Enter a Valid Name";  
        }
        if (this.props.isNewStudent && !studentMobile) {
            formIsValid = false;
            errors["studentMobile"] = "Mobile number cannot be empty";
        }
        if (this.props.isNewStudent && studentMobile && (studentMobile.length<10 || studentMobile.length > 10)) {
            formIsValid = false;
            errors["studentMobile"] = "Please enter a valid 10 digit mobile number";
        }
       
        this.setState({ errors: errors });
        return formIsValid;
    
	};
	handleSubmit = (event) => {
		event.preventDefault();
	};
	signUp = () => {
		if (this.handleValidation()) {
			this.props.studentsEmail(this.state.studentEmail);
			this.props.currentComponent(constants.SHOWCOMPONENT.ENTERCODE);
		}
	};
	studentSignUp = () => {
		if (this.handleValidation()) {
			this.props.setStudentsEmail(this.state.studentEmail)
			this.props.setUserEmail(this.state.email);
			this.setState({ isLoading: true });             
			  this.props.setSignUpUser(this.state);

					// this.props.setSignUpUser(this.state);
			let reqData = {
				name: this.state.name,
				email: this.state.email,
				phone: this.state.mobileNo,
				role_id:
					this.props.signUpType ===
					constants.SHOWCOMPONENT.PARENTSIGNUP
						? constants.ROLE_ID.PARENT
						: constants.ROLE_ID.STUDENT,
				social_sign_up: true,
				batch_id: this.state.selectedBatch?.value,
			};
			if (
				this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP
			) {
				reqData.details = {
					name: this.state.studentName,
					email: this.state.studentEmail,
				};
				reqData.parent_social_signup = true;
			}
			if (this.props.isNewStudent) {
                reqData.details.register_student_user = true;
                reqData.details.phone = this.state.studentMobile;
                reqData.details.role_id = constants.ROLE_ID.STUDENT;
                reqData.details.center_id = this.state.selectedCenter ? this.state.selectedCenter.value : [];
                reqData.details.batch_id = this.state.selectedBatch ? this.state.selectedBatch.value : [];
            }
			const { access_token } = this.props.socialMediaData;
			// const access_token=accessToken
			UserService.getSocialMediaInfo(
				access_token,
				reqData,
				this.props.platform,
				(err, response) => {
					this.setState({ isLoading: false });
					if (!err && response) {
						if (this.props.signUpType ===
							constants.SHOWCOMPONENT.PARENTSIGNUP) {
							this.props.setIsSignup(true)
							this.props.setCurrentComponent(constants.SHOWCOMPONENT.ENTERCODE);
					  
						}else{

							localStorage.setItem(
								'userToken',
								JSON.stringify(response.token)
							);
							this.handleCookie(JSON.stringify(response.token));
							localStorage.setItem(
								'user',
								JSON.stringify(response.user)
							);
							this.props.setUser(response.user);
							this.handleCookie(JSON.stringify(response.token));
							this.props.goToDashboard();
							this.props.closeLoginModal(false);
						}
					} else {
						toast.error(err, {
							autoClose: constants.TOAST_TIMEOUT_MEDIUM
						});
					}
				}
			);
		}
	};
	setData = () => {
		const { name, email } = this.props.socialMediaData;
		this.setState({ name: name, email: email });
	};
	validateEmail = (email) => {
        let formIsValid = true;
        if (!this.isValidEmail(email)) {
            formIsValid = false;
          }
        if(email){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
    
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2 && (email.length - lastDotPos) < 5)) {
                formIsValid = false;
            }
        }
        return formIsValid
    }
	isValidEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            return true;
        }
        return false;
    }
	selectedCenter = (selectedCenter) => {
        this.setState({selectedCenter: selectedCenter});
        this.getBatches(selectedCenter);
    }
    selectedBatch = (selectedBatch) => {
        this.setState({selectedBatch: selectedBatch});
    }
	render() {
		return (
			<div className='reset-pass'>
				<Form onSubmit={this.handleSubmit}>
					<div className='form-min-block-1'>
						<Form.Group className='mb-3'>
							<div className='cat-form-title-bloacks'>
								You have successfully verified your email ID.{' '}
								<br></br>All we need are a couple of more
								details
							</div>
							<Form.Label className='input-label'>
								Name
							</Form.Label>
							<Form.Control
								name='name'
								type='text'
								placeholder='Enter Name'
								onChange={this.onChangeValue}
								value={this.state.name}
							/>
							<div className='error-msg error-msg-10'>
								{this.state.errors['name']}
							</div>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label className='input-label'>
								Email
							</Form.Label>
							<Form.Control
								name='email'
								type='text'
								placeholder='Enter Email'
								onChange={this.onChangeValue}
								value={this.state.email}
								disabled={
									this.props.socialMediaData &&
									this.props.socialMediaData.email &&
									this.props.socialMediaData.email.length > 0
										? true
										: false
								}
							/>
							<div className='error-msg error-msg-10'>
								{this.state.errors['email']}
							</div>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label className='input-label'>
								Mobile No.
							</Form.Label>
							<Form.Control
								name='mobileNo'
								type='number'
								placeholder='Enter Mobile No.'
								onChange={this.onChangeValue}
								value={this.state.mobileNo}
								onWheel={ event => event.target.blur() } 
								onKeyDown={this.handleKeyDown} 
								onPaste={this.onPaste}
							/>
							<div className='error-msg error-msg-10'>
								{this.state.errors['mobileNo']}
							</div>
						</Form.Group>
						{this.props.signUpType ===
							constants.SHOWCOMPONENT.PARENTSIGNUP && (
							<Form.Group className='mb-3'>
								<Form.Label className='input-label'>
									Student Email ID
								</Form.Label>
								<Form.Control
									name='studentEmail'
									type='email'
									ref={this[constants.SIGNUP_REFS.PARENT_REF]}
									placeholder='Enter Email ID'
									onChange={this.onChangeValue}
								/>
								<div className='error-msg error-msg-10'>
									{this.state.errors['studentEmail']}
								</div>
							</Form.Group>
						)}
                    {this.props.isNewStudent && <div className="student-txt-email">
                        The Student email ID entered by you is not registered. Please provide the following information to register the above Email ID as a new student.
                    </div>}
						{this.props.isNewStudent && (
							<Form.Group className='mb-3'>
								<Form.Label className='input-label'>
									Student Name
								</Form.Label>
								<Form.Control
									name='studentName'
									value={this.state.studentName}
									type='text'
									placeholder='Enter Name'
									onChange={this.onChangeValue}
								/>
								<div className='error-msg error-msg-10'>
									{this.state.errors['studentName']}
								</div>
							</Form.Group>
						)}
						{this.props.isNewStudent && (
							<Form.Group className='mb-3'>
								<Form.Label className='input-label'>
									Student Mobile No.
								</Form.Label>
								<Form.Control
									name='studentMobile'
									value={this.state.studentMobile}
									type='number'
									placeholder='Enter Mobile No.'
									onChange={this.onChangeValue}
									onWheel={ event => event.target.blur() } 
									onKeyDown={this.handleKeyDown} 
									onPaste={this.onPaste}
								/>
								<div className='error-msg error-msg-10'>
									{this.state.errors['studentMobile']}
								</div>
							</Form.Group>
						)}
						{(this.props.signUpType ===
							constants.SHOWCOMPONENT.STUDENTSIGNUP ||
							this.props.isNewStudent)  && (
							<div className='drop-down-outer'>
								<Form.Group className='mb-3 width-100'>
									<Form.Label className='input-label'>
										Target Exam
									</Form.Label>
									<Select
										name='targetExam'
										value={this.state.selectedCenter}
										placeholder='Target Exam'
										onChange={this.selectedCenter}
										options={this.state.centers}
									/>
									<div className='error-msg error-msg-10'>
										{this.state.errors['targetExam']}
									</div>
								</Form.Group>

								<Form.Group className='mb-3 width-100'>
									<Form.Label className='input-label'>
										Target Year
									</Form.Label>
									<Select
										name='targetYear'
										value={this.state.selectedBatch}
										placeholder='Target Year'
										onChange={this.selectedBatch}
										options={this.state.batches}
									/>
									<div className='error-msg error-msg-10'>
										{this.state.errors['targetYear']}
									</div>
								</Form.Group>
							</div>
						)}
					</div>
					<Form.Group className='mb-3'>
						<Button
							buttonStyle={this.state.isLoading ? "red-btn btn-full-size no-margin loading-btn-disable" : "red-btn btn-full-size no-margin loading-button"} loading={this.state.isLoading} type="button" 
							onClick={this.studentSignUp}
						>
					{this.state.isLoading && <Loading/>}
                    {!this.state.isLoading && `SIGN UP`}						</Button>
					</Form.Group>
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.webSiteUser.user,
	platform: state.webSiteUser.platform,
    signUpUser: state.webSiteUser.signUpUser,
	socialMediaData: state.webSiteUser.socialMediaData,
});
const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setCurrentComponent: (currentComponent) => dispatch(setCurrentComponent(currentComponent)),
    setSignUpUser: signUpUser => dispatch(setSignUpUser(signUpUser))
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withCookies(SocialSignUp));
