import React from "react";
import "../styles/Button.css";
const SIZES = ["btn--medium", "btn--large"];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  disabled
}) => {
  const checkButtonStyle = buttonStyle;

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    // <Link to="/aboutme" className="btn-mobile">
      <button
        className={`btn ${checkButtonStyle} ${checkButtonSize}  pointer`}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    // </Link>
  );
};
