import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { setCurrentComponent, showLoader } from '../../actions'
import UserService from '../../services/UserService'
import * as constants from '../../constants';

export class NotificationMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    resendCode = (e) =>{
            e.preventDefault();
            // this.setState({time: {minutes: 0, seconds: 0}})            
            this.props.showLoader(true);
            let errors = {};
            let reqData = {
              userId: this.props.reqData.email,
            };
            UserService.sendOtp(reqData,  (err, response) =>{
              if (err) {
                this.props.showLoader(false);
                errors["response"] = err.message;
                // this.setState({ errors: errors });
                // this.toast(err);
              }
              else {
                this.props.showLoader(false);
                this.props.setCurrentComponent(constants.SHOWCOMPONENT.ENTERCODE);
        
                // this.setState({otp: response});
                // this.setState({codeExpired: false,timer: null});
                // this.startTimer();
              }
            });
        
          
          
    }
    render() {
        return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <div>This email ID is already registered as a student but is not yet verified. <a onClick={this.resendCode}>Click here to get the verification code and complete the verification.</a></div>
        )
    }
}
const mapStateToProps = state => ({
  loader: state.webSiteUser.loader
})
const mapDispatchToProps = dispatch => ({
  setCurrentComponent: currentComponent => dispatch(setCurrentComponent(currentComponent)),
  showLoader: loader => dispatch(showLoader(loader)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(NotificationMessage))