/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Row ,Col, Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "./Button";
import "../styles/Navbar.css";
import logo from '../images/cat/logo.png'
import Logout from '../images/cat/power.png'
import close from '../images/cat/close-icon.png'
import twoDoth from '../images/cat/two-doth.png'
import * as constants from "../constants";
import ResendCode from "./ResendCode";
import Login from "./Login";
import SignUp from "./SignUp";
import ResetPassword from "./ResetPassword";
import NewPassword from "./NewPassword";
import SocialSignUp from "./SocialSignUp";
import { useCookies } from 'react-cookie';
import UserService from "../services/UserService";
import { connect } from "react-redux";
import { setCurrentComponent, setSignUpUser, setUser } from "../actions";

function Navbar(props) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [viewingPricing, setViewingPricing] = useState(true);
  const [isNewStudent, setIsNewStudent] = useState(false);
  const handleClick = () => setClick(!click);
  // const [isOpen, setIsOpen] = React.useState(false);
  // const [currentComponent, setCurrentComponent] = useState("LOGIN");
  const [userImage, setUserImage] = useState(constants.SHOWIMAGE.STUDENTLOGINIMAGE);
  const [studentEmail, setStudentEmail] = useState("");
  const [isSignUp, setIsSignup] = useState(false);
  const [parentEmail, setParentEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [signUpType, setSignUpType] = useState("");
  const [passwordToken, setPasswordToken] = useState("");
  const [cookie,setCookie,removeCookie] = useCookies(['user']);
  const [signUpRef, setSignUpRef] = useState("");
  const validatePassword=(password)=>{
    return /\s/g.test(password.trim());
  }

  const closeMobileMenu = () => {
    setClick(false);
    setViewingPricing(true);
    window.scrollTo(0, 0)
  }

  const toPricing = () => {
    setClick(false);
    setViewingPricing(true);
    window.scrollTo(0, 0)
  }
  const goToDashboard = () => {
        removeCookie('upgrade', constants.COOKIE_OPTIONS);
        setCookie('redirectFromWebsite',true,constants.COOKIE_OPTIONS);
        setCookie('websiteUser',true,constants.COOKIE_OPTIONS);
        window.location.href = process.env.REACT_APP_REDIRECT_LOGIN_URL + 'dashboard/home';
  }
  const logOut = () => {
    props.setUser(null);
    console.log(cookie);
    removeCookie('upgrade', constants.COOKIE_OPTIONS);
    removeCookie('userToken', constants.COOKIE_OPTIONS);
    removeCookie("keepMeloggedIn", constants.COOKIE_OPTIONS);
    removeCookie("isLoggedIn", constants.COOKIE_OPTIONS);
    UserService.logout(() => {
      localStorage.clear();
    props.setUser(null);
    removeCookie("isLoggedIn", constants.COOKIE_OPTIONS);
  });
    
  }
  const handleModal = (modalComponent) => {
    props.setCurrentComponent(modalComponent);
      setStudentEmail("");
      setParentEmail("");
      setUserEmail("");
      setSignUpType("");
      setIsNewStudent(false);
      props.setSignUpUser('')
      props.setIsOpen(!props.isOpen);
      // props.setCurrentComponent('')
  };
  const showTenDayBtn=()=>{
    return window.location.href.indexOf('ForStudents') <= -1;

  }
  const showMSTBtn=()=>{
    return window.location.pathname==="/";
  }
  return (
    <>
      <nav id= "navbar" className="navbar">
        <div className="navbar-container">
          <div className="round-in-header"></div>
          <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={logo} alt="Projects" className="logo-img"></img>
          </NavLink>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink to="/" exact className="nav-links" onClick={closeMobileMenu}>
              For Institute
              </NavLink>
            </li>
            <li className="nav-item">
              <div
                
                className="nav-links cursor-pointer"
                onClick={props.redirectToMYT}
              >
                For Teacher
              </div>
            </li>
            <li className="nav-item">
              <NavLink
                to="/ForStudents"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                For Student
              </NavLink>
            </li>
          </ul>
          {props.scrollUp && button && viewingPricing && !showTenDayBtn() && <div className="ten-days-ontop"><Button disabled={props.user !== null}  onClick={()=>handleModal(constants.SHOWCOMPONENT.SIGNUP)} buttonStyle="blue-btn">30 DAYS FREE TRIAL</Button></div>}
          {props && props.user && !showMSTBtn() ?  <div className="logout-buttons"><Button onClick={goToDashboard} buttonStyle="red-btn goto-dashboard">Go To Dashboard</Button>
           <Button onClick={logOut} buttonStyle="logout-btnn-head"><img src={Logout} alt="logout" className="logout-icon"/></Button></div>: !showMSTBtn() && <Button onClick={()=>handleModal(constants.SHOWCOMPONENT.LOGIN)} buttonStyle="red-btn">LOGIN</Button>}
           {showMSTBtn() && <Button onClick={props.redirectToMYT} buttonStyle="red-btn">Make Sample Test</Button>}
        </div>
      </nav>
      <Modal size="1280"
      show={props.isOpen} onHide={props.currentComponent !== constants.SHOWCOMPONENT.CONTINUE ? ()=>handleModal('') : ''}
      centered >
          <Row>
            <Col md={5} className={userImage === constants.SHOWIMAGE.STUDENTLOGINIMAGE ? "padding-lf-0 login-bg-img-stud": userImage === constants.SHOWIMAGE.STUDENTSIGNUPIMAGE ? "signup-bg-img-stud" : "signup-bg-img-parent"}>
            <div className="display-img-bg"> {props.currentComponent === constants.SHOWCOMPONENT.SIGNUP && <div className="display-img-text1  pointer">Have An Account? <div className="display-img-text2"><v-btn class="underline  pointer" onClick={() => {props.setCurrentComponent(constants.SHOWCOMPONENT.LOGIN); props.setIsOpen(true)}}>Login</v-btn></div></div>}
              {props.currentComponent === constants.SHOWCOMPONENT.LOGIN && <div className="display-img-text1">Don't Have An Account?<div className="display-img-text2"><v-btn  class="underline  pointer" onClick={() => props.setCurrentComponent(constants.SHOWCOMPONENT.SIGNUP)}>Sign Up</v-btn></div></div>}
            </div>
            </Col>
            <Col md={7}>
              <div className={`detail-block ${props.currentComponent === constants.SHOWCOMPONENT.ENTERCODE || props.currentComponent === constants.SHOWCOMPONENT.CONTINUE ? 'detail-block-resend' : '' }`}>
                <img src={twoDoth} alt="Logo" className="two-doth-img"></img>
                <div className="modal-header-cust">
                  <div><img src={logo} alt="Logo" className="popup-logo-img"></img></div>
                  {props.currentComponent !== constants.SHOWCOMPONENT.CONTINUE ?
                    <div className=" pointer" onClick={()=>handleModal('')}><img src={close} alt="close" className="popup-close-img"></img></div>
                    : ''}
                </div>
                <div className="login-mobile-show">
                    {props.currentComponent === constants.SHOWCOMPONENT.SIGNUP && <div className="display-img-text1  pointer">Have An Account? <div className="display-img-text2"><v-btn class="underline" onClick={() => {props.setCurrentComponent(constants.SHOWCOMPONENT.LOGIN); props.setIsOpen(true)}}>Login</v-btn></div></div>}
                    {props.currentComponent === constants.SHOWCOMPONENT.LOGIN && <div className="display-img-text1  pointer">Don't Have An Account?<div className="display-img-text2"><v-btn  class="underline" onClick={() => props.setCurrentComponent(constants.SHOWCOMPONENT.SIGNUP)}>Sign Up</v-btn></div></div>}
                </div>
                <div className={userImage === constants.SHOWIMAGE.PARENTSIGNUPIMAGE && (props.currentComponent === constants.SHOWCOMPONENT.SIGNUP || props.currentComponent === constants.SHOWCOMPONENT.SOCIALSIGNUP) ?  'scroll-block form-block' : 'form-block'}>
                  {props.currentComponent === constants.SHOWCOMPONENT.LOGIN &&
                    <Login 
                      renderImage={(showUserImage) => setUserImage(showUserImage)}
                      closeLoginModal={(closeModal) => props.setIsOpen(closeModal)}
                      setUsersEmail={(usersEmail) => setUserEmail(usersEmail)}
                      saveActivity={() => props.saveActivity()}
                      setIsSignup={(isSignUp)=> setIsSignup(isSignUp)}
                      setUserEmail={(userEmail) => setUserEmail(userEmail)}
                      validatePassword={(password) => validatePassword(password)}
                      setSignUpType={(signUpType) => setSignUpType(signUpType)}
                      setIsSocialSignUp={(isSocialSignUp)=>props.setIsSocialSignUp(isSocialSignUp)}
                    /> 
                  }
                  {props.currentComponent === constants.SHOWCOMPONENT.SIGNUP &&
                    <SignUp 
                      setStudentsEmail={(studentsEmail) => setStudentEmail(studentsEmail)}
                      setSignUpType={(signUpType) => setSignUpType(signUpType)}
                      renderImage={(showUserImage) => setUserImage(showUserImage)}
                      setUserEmail={(userEmail) => setUserEmail(userEmail)}
                      setIsSignup={(isSignUp)=> setIsSignup(isSignUp)}
                      setIsNewStudent={(isNewStudent)=> setIsNewStudent(isNewStudent)}
                      isNewStudent={isNewStudent}
                      signUpRef = {signUpRef}
                      setIsSocialSignUp={(isSocialSignUp)=>props.setIsSocialSignUp(isSocialSignUp)}
                      setSignUpRef={(signUpRef)=> setSignUpRef(signUpRef)}
                    /> 
                  }
                  {(props.currentComponent === constants.SHOWCOMPONENT.SOCIALSIGNUP) && 
                    <SocialSignUp 
                      setStudentsEmail={(studentsEmail) => setStudentEmail(studentsEmail)}
                      setUserEmail={(userEmail) => setUserEmail(userEmail)}
                      signUpType={signUpType}
                      closeLoginModal={(closeModal) => props.setIsOpen(closeModal)}
                      setIsSignup={(isSignUp)=> setIsSignup(isSignUp)}
                      setIsNewStudent={(isNewStudent)=> setIsNewStudent(isNewStudent)}
                      signUpRef = {signUpRef}
                      isNewStudent={isNewStudent}
                      goToDashboard={()=> goToDashboard()}
                      /> 
                  }
                  {props.currentComponent === constants.SHOWCOMPONENT.FORGOTPASSWORD && 
                    <ResetPassword 
                      setUserEmail={(userEmail) => setUserEmail(userEmail)}
                      isNewStudent={isNewStudent}
                      setSignUpType={(signUpType) => setSignUpType(signUpType)}
                    /> 
                  }
                  {props.currentComponent === constants.SHOWCOMPONENT.ENTERCODE && 
                    <ResendCode 
                      setToken={(token) => setPasswordToken(token)}
                      studentEmail={studentEmail}
                      userEmail={userEmail}
                      isNewStudent={isNewStudent}
                      signUpType={signUpType}
                      isSocialSignUp={props.isSocialSignUp}
                      setSignUpRef={(signUpRef)=> setSignUpRef(signUpRef)}
                    />
                  }
                  {props.currentComponent === constants.SHOWCOMPONENT.CONTINUE && 
                    <NewPassword 
                      closeLoginModal={(closeModal) => props.setIsOpen(closeModal)}
                      userEmail={userEmail}
                      studentEmail={studentEmail}
                      signUpType={signUpType}
                      token={passwordToken}
                      parentEmail={studentEmail}
                      isNewStudent={isNewStudent}
                      isSignUp={isSignUp}
                      goToDashboard={()=> goToDashboard()}
                      handleModal={()=> handleModal("")}
                      validatePassword={(password) => validatePassword(password)}
                      setUsersEmail={(usersEmail) => setUserEmail(usersEmail)}
                      setToken={(token) => setPasswordToken(token)}
                      isSocialSignUp={props.isSocialSignUp}
                      setIsSocialSignUp={(isSocialSignUp)=>props.setIsSocialSignUp(isSocialSignUp)}
                    />
                  }
                </div>
              </div>
            </Col>
          </Row>
    </Modal>
    </>
  );
}
const mapStateToProps = state => ({
  user: state.webSiteUser.user,
  currentComponent: state.webSiteUser.currentComponent,
})
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
    setSignUpUser: signUpUser => dispatch(setSignUpUser(signUpUser)),
    setCurrentComponent: currentComponent => dispatch(setCurrentComponent(currentComponent)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar)
