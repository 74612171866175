import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Button } from "./Button";
import UserService from "../services/UserService";
import * as constants from "../constants";
import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { setCurrentComponent, setUser, showLoader } from "../actions";
export class NewPassword extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      reEnteredPassword: "",
      errors: {},
      userEmail: "",
      studentEmail: "",
      typingTimeout:0,
      userId:""
    };
  }
  componentDidMount = () => {
    this.props.userEmail ? this.setState({userEmail: this.props.userEmail}) : this.setState({userEmail: null})
}
  onChangeValue = (e) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
   }
    if (e.target.name==='userId') {
      this.setState({
        [e.target.name]: e.target.value,
        typingTimeout: setTimeout( () => {
            this.checkUserNameAvailability(e.target.value);
          }, 1000)
     });
    }
    this.setState({ [e.target.name]: e.target.value });
  }
  handleKeyDown(e){
    if (e.key === " ") {
      e.preventDefault();
    }
  }
  handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.password || !this.state.reEnteredPassword) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty";
    } else if(this.props.validatePassword(this.state.password) || this.props.validatePassword(this.state.reEnteredPassword)){
      formIsValid = false;
      errors["password"] = "Spaces are not allowed";
    } else if (this.state.password.trim().length < 6 || this.state.reEnteredPassword.trim().length < 6) {
      formIsValid = false;
      errors["password"] = "Password length should be of minimum 6 characters";
    } else if(this.state.password !== this.state.reEnteredPassword) {
      formIsValid = false;
      errors["password"] = "Passwords do not match";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  setPassword = (e) => {
    e.preventDefault();
    if(this.handleValidation()){
      let me = this;
      me.props.showLoader(true);
      let errors= {};
      let reqData = {
        userId: me.state.userEmail?me.state.userEmail: "",
        password: me.state.reEnteredPassword,
        is_signup: this.props.isSignUp,
        parent_social_signup: this.props.signUpType === constants.SHOWCOMPONENT.PARENTSIGNUP ? this.props.isSocialSignUp: false
    };
      let fn = 'setPassword'
      if (this.props.token) {
        reqData.token=this.props.token;
        reqData.userId=this.props.userEmail;
        fn = 'resetPassword'
      }
      if (this.props.user && !this.props.user.username) {
        reqData.username =this.props.userEmail
      }
      UserService[fn](reqData, function (err, response) {
        me.setState({isLoading: false});
        if (err) {
          me.props.showLoader(false);
          errors["response"] = err.message;
          me.setState({ errors: errors });
        }
        else if(!err && response){
          me.props.showLoader(false);
          me.props.setUsersEmail(null)
          me.props.setToken(null)
          me.setState({
            userEmail:"",
            password:"",
            reEnteredPassword:""
          })
          if (me.props.isSignUp) {
            localStorage.setItem('userToken', JSON.stringify(response.token));
            me.handleCookie(JSON.stringify(response.token));
            localStorage.setItem('user', JSON.stringify(response.user));
            sessionStorage.removeItem('signUpFormData');
            me.props.setUser(response.user);
            me.props.handleModal();
            me.props.goToDashboard();
            me.props.closeLoginModal(false);
          }else{
            me.props.signUpType !== constants.SHOWCOMPONENT.FORGOTPASSWORD  ? me.props.setCurrentComponent(constants.SHOWCOMPONENT.LOGIN) : me.props.closeLoginModal(false) 
          }
        }
      });
  }

  }
  handleSubmit = (event) => {
      event.preventDefault();
  }
  handleCookie = (token) => {
    const { cookies } = this.props;
    if(token){
      cookies.set("userToken", token, constants.COOKIE_OPTIONS); // setting the cookie
    }
  }
  render() {
    return (
      <div className="reset-pass">
          <Form >
              <div className="form-min-block">
                <div className="cat-form-title-bloack">Please type your New Password</div>
                <Form.Group className="mb-3 re-enter-pass" controlId="formPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control name="password" placeholder="New Password" type={this.state.viewPassword1 ? 'text' : 'password'} onChange={this.onChangeValue} onKeyDown={this.handleKeyDown}/>
                    <div className="font-icon">
                        <i onClick={() => { this.setState({ viewPassword1: !this.state.viewPassword1 }) }} className={this.state.viewPassword1 ? "fas fa-eye" : "fas fa-eye-slash"}/>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3 re-enter-pass" controlId="formReenterPassword">
                    <Form.Label>Re-Enter Password</Form.Label>
                    <Form.Control name="reEnteredPassword" placeholder="Re-Enter Password" type={this.state.viewPassword2 ? 'text' : 'password'} onChange={this.onChangeValue} onKeyDown={this.handleKeyDown}/>
                    <div className="font-icon">
                        <i onClick={() => { this.setState({ viewPassword2: !this.state.viewPassword2 }) }} className={this.state.viewPassword2 ? "fas fa-eye" : "fas fa-eye-slash"}/>
                    </div>
                </Form.Group>
                <div className="error-msg">{this.state.errors['password']}</div>
            </div>
            <Form.Group className="mb-3 mt-5">
                <Button buttonStyle="red-btn btn-full-size" type="button" onClick={this.setPassword}>
                {this.props.signUpType !== constants.SHOWCOMPONENT.FORGOTPASSWORD ? `SIGN UP` : `DONE`}
                </Button>
            </Form.Group>
            </Form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.webSiteUser.user,
  loader: state.webSiteUser.loader
})
const mapDispatchToProps = dispatch => ({
  setCurrentComponent: currentComponent => dispatch(setCurrentComponent(currentComponent)),
  setUser: user => dispatch(setUser(user)),
  showLoader: loader => dispatch(showLoader(loader)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(NewPassword))