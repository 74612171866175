import React, { Component, Suspense } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Navbar from "./components/Navbar";
import {  Switch, Route, BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import './styles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';
import { setCurrentComponent, setPlatForm, setSocialMediaData, setUser, setSignUpUser, showLoader } from './actions';
import UserService from './services/UserService';
import * as constants from './constants';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { ToastContainer, toast } from 'react-toastify';
import createActivityDetector from 'activity-detector';

const ForStudents = React.lazy(() => import("./pages/ForStudents"));
//const Pricing = React.lazy(() => import("./pages/Pricing"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("./pages/TermsOfService"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const InstiuteHome = React.lazy(() => import("./pages/InstiuteHome"));
const activityDetector = createActivityDetector({
  timeToIdle: 1000, // wait 5s of inactivity to consider the user is idle
  autoInit: false, // I don't want to start the activity detector yet.
  ignoredEventsWhenIdle: []
});
export class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props){
    super(props)
    this.state={
      isOpen:false,
      scrollUp: false,
      isSocialSignUp:false,
    }
  }
  componentWillMount(){
    let start, end = 0;
    this.interval = setInterval( () =>{
      var ttime = JSON.parse(localStorage.getItem('active_total_time'))
      var reqData = {
        "total_time": ttime ? ttime : 0
      };
      
      let userToken = JSON.parse(localStorage.getItem('userToken'));
      if (userToken && userToken.length) {
        UserService.saveActivities(reqData, (err, response) => {
          if (err) {
            // errors["response"] = err.message;
            // me.setState({errors: errors});
          }
          else {
            // Success
            localStorage.setItem('active_total_time', JSON.stringify(0));
          }
        });
      }

    }, 60000);



    activityDetector.on('idle', () => {
      end = new Date();
      if (end && start) {
        let time = JSON.parse(localStorage.getItem('active_total_time'))

        let betTime =  time+ (end - start);

        localStorage.setItem('active_total_time', JSON.stringify(betTime));
      }
    });
    activityDetector.on('active', () => {
      start = new Date();
    });
    activityDetector.init();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  componentDidMount(){
    let user = localStorage.getItem('user');
    this.props.setUser(JSON.parse(user));
    this.props.showLoader(false);
    const { cookies } = this.props;
    let keepMeLoggedIn = JSON.parse(localStorage.getItem('keepMeLoggedIn'));
    let userLoggedIn = JSON.parse(sessionStorage.getItem('userLoggedIn'));
    this.handleLoginUser(cookies);
    if (!cookies.cookies.userToken || (!userLoggedIn && !keepMeLoggedIn)) {
      this.flushData();
    }
    if (window.opener && window.opener !== window) {
      const code = this.getCodeFromWindowURL(window.location.href,'code');
      window.opener.postMessage({'type': 'code', 'code': code}, '*')
      window.close();
    }
    window.addEventListener('message', this.handlePostMessage);
    this.save();
    
    if(localStorage.getItem('userToken')){
      UserService.me((err,res)=>{
        if (!err) {
          localStorage.setItem('user', JSON.stringify(res));
          this.props.setUser(res);
          this.setIsOpen(false);
          this.save();
        }else{
          this.props.setUser(null);
        }

      })
    }
    
  }
  handlePostMessage = event => {
    if (event.data.type === 'code') {
      const { code } = event.data;
      this.getUserCredentials(code);
    }
  };
  getCodeFromWindowURL = (url,code) => {
      const popupWindowURL = new URL(url);
      return popupWindowURL.searchParams.get(code);
  };
  redirectToMYT = () => {
    window.open(constants.MYT_REDIRERT_URL, '_blank');
  }

  getUserCredentials = xcode => {
    this.props.setPlatform(constants.SOCIAL_MEDIA.LINKEDIN)
    UserService.getLinkedInInfo({socialMedia:constants.SOCIAL_MEDIA.LINKEDIN,code:xcode},(err,response)=>{
      if (!err && response) {
        if (this.props.type === constants.TYPE.SIGNUP) {
          const socialMediaData = {
            name: response.profile.name.givenName + ' ' + response.profile.name.familyName,
            email: response.profile.emails[0].value,
            access_token: response.accessToken
          }
          this.setIsSocialSignUp(true)
          this.props.setSocialMediaData(socialMediaData);
          if (response.accessToken) {
            
            this.props.setCurrentComponent(constants.SHOWCOMPONENT.SOCIALSIGNUP)
          }        
        }else {
          this.socialLogin(response)
        }
      }
    })
  };
  socialLogin = (response) =>{
    let access_token = response.accessToken;
    let reqData = {
      social_login: true,
    };
    UserService.getSocialMediaInfo(
      access_token,
      reqData,
      constants.SOCIAL_MEDIA.LINKEDIN,
      (err, response) => {
        if (!err && response) {
          localStorage.setItem(
            'userToken',
            JSON.stringify(response.token)
          );
          this.handleCookie(JSON.stringify(response.token));
          localStorage.setItem(
            'user',
            JSON.stringify(response.user)
          );
          this.props.setUser(response.user);
          this.setIsOpen(false);
        } else if(err.code==="ERR006") {
          toast.error(err.message, {
            theme:'colored',
            autoClose: constants.TOAST_TIMEOUT_MEDIUM
          });
        }
      }
    );
  }
  handleCookie = (token) => {
		const { cookies } = this.props;
		if (token) {
			cookies.set('userToken', token, constants.COOKIE_OPTIONS); // setting the cookie
		}
	};
  setIsOpen = (open) => {
    this.setState({isOpen:open})
  }
  flushData(){
    const { cookies } = this.props;
    this.props.setUser(null);
    this.props.setSignUpUser(null);
    cookies.remove("upgrade", constants.COOKIE_OPTIONS);
    cookies.remove("userToken", constants.COOKIE_OPTIONS);
    cookies.remove("keepMeloggedIn", constants.COOKIE_OPTIONS);
    localStorage.clear();
    clearInterval(this.interval);

  }
  save = () => {
    const { cookies } = this.props;
    this.interval = setInterval(() => {
        if (!cookies.cookies.userToken) {
          this.flushData();
        }
    }, 60000);
  }
  showTenDayFree=()=>{
    if(window.innerWidth <= 950){
      if (window.pageYOffset > 200) {
        this.setState({scrollUp : true})
      } else {
        this.setState({scrollUp : false})
      }
    }else if(window.pageYOffset > 420) {
      this.setState({scrollUp : true})
    } else {
      this.setState({scrollUp : false})
    }
  }
  setTenDayButton = () => {
    this.setState({scrollUp : true});
  }
  setIsSocialSignUp = (isSocialSignUp) => {
    this.setState({isSocialSignUp:isSocialSignUp})
  }
  handleLoginUser = (cookies) => {
    if(cookies.cookies.userToken){
      let me = this,
       reqData = {
        token: JSON.parse(cookies.cookies.userToken),
        website: true,
        external: true,
      };
      UserService.login(reqData, function (err, res) {
        if(!err && res){
          me.setState({ isLoading: false });
          localStorage.setItem('userToken', JSON.stringify(res.token));
          me.handleCookie(JSON.stringify(res.token));
          sessionStorage.setItem('userLoggedIn', true);
          localStorage.setItem('user', JSON.stringify(res.user));
          me.props.setUser(res.user);
        }
      })
    }
  }
    render(){
    return (
      <>
        <head>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff"></meta>
        </head>
        <ToastContainer hideProgressBar={true}/>
        <BrowserRouter>
          <Navbar saveActivity={this.save} redirectToMYT={this.redirectToMYT} setIsOpen={(open)=>this.setIsOpen(open)} isOpen={this.state.isOpen} scrollUp={this.state.scrollUp} isSocialSignUp={this.state.isSocialSignUp} setIsSocialSignUp={(isSocialSignUp)=>this.setIsSocialSignUp(isSocialSignUp)}/>
          <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/" render={(props)=><InstiuteHome {...props} setIsOpen={(open)=>this.setIsOpen(open)} redirectToMYT={this.redirectToMYT} scrollUp={this.state.scrollUp} showTenDayFree={()=>this.showTenDayFree()}/>} />
            <Route path="/ForStudents" exact render={(props)=><ForStudents {...props} setTenDayButton= {() => this.setTenDayButton()} setIsOpen={(open)=>this.setIsOpen(open)} scrollUp={this.state.scrollUp} showTenDayFree={()=>this.showTenDayFree()} />} />
            <Route path="/ContactUs" exact render={(props)=><ContactUs {...props}/>} />
            {/* <Route path="/Pricing" exact render={(props)=><Pricing {...props} setIsOpen={(open)=>this.setIsOpen(open)} setTenDayButton= {() => this.setTenDayButton()} />} /> */}
            <Route path="/TermsOfService" exact render={(props)=><TermsOfService {...props}/>} />
            <Route path="/PrivacyPolicy" exact render={(props)=><PrivacyPolicy {...props}/>} />
          </Switch>
          </Suspense>
          <Footer redirectToMYT={this.redirectToMYT} />
        </BrowserRouter>
      </>
    );

  }
}
const mapStateToProps = state => ({
  user: state.webSiteUser.user,
  type: state.webSiteUser.type,
  loader: state.webSiteUser.loader
})
const mapDispatchToProps = dispatch => ({
  setSocialMediaData: data => dispatch(setSocialMediaData(data)),
  setPlatform: platform => dispatch(setPlatForm(platform)),
  setUser: user => dispatch(setUser(user)),
  setCurrentComponent: currentComponent => dispatch(setCurrentComponent(currentComponent)),
  setSignUpUser: signUpUser => dispatch(setSignUpUser(signUpUser)),
  showLoader: loader => dispatch(showLoader(loader)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(App))
