import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Loading from "./Loader";
import { Button } from "./Button";
import UserService from "../services/UserService";
import * as constants from "../constants";
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import { setCurrentComponent, setType, setUser } from "../actions";
export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {},
      dontGetCode: false,
      otp: "",
      isLoading:false
    };
  }
  onChangeValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleValidation = () => {
    let email = this.state.email;
    let errors = {};
    let formIsValid = true;

    //Email
    if(!email){
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }

    if(email){
        let lastAtPos = email.lastIndexOf('@');
        let lastDotPos = email.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2 && (email.length - lastDotPos) < 5)) {
            formIsValid = false;
            errors["email"] = "Email is not valid";
          }
    }
    this.setState({errors: errors});
    return formIsValid;
  }
  getCode = () => {
    if(this.handleValidation()) {
      this.setState({ isLoading: true });
      let errors = {};
      let reqData = {
            userId: this.state.email,
            email: this.state.email,
            mode: 'email',
            website_user: true,
            forget_password: true
          };
      UserService.forgotPassword(reqData, (err, response) => {
        if (err) {
          errors["response"] = err.message;
          this.setState({ errors: errors });
          this.setState({ isLoading: false });
        } else{

          this.setState({ isLoading: false });
          this.props.setCurrentComponent(constants.SHOWCOMPONENT.ENTERCODE)
          this.props.setSignUpType(constants.SHOWCOMPONENT.FORGOTPASSWORD);
        } 
      });
      this.props.setUserEmail(this.state.email)
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
  }
  render() {
    return (
      <div className="reset-pass">
          <Form onSubmit={this.handleSubmit}>
              <div className="form-min-block">
                <div className="cat-form-title">Reset Password</div>
                <div className="cat-form-sub-title">Please enter your registered email ID</div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email ID</Form.Label>
                    <Form.Control onChange={this.onChangeValue} name="email" type="email" placeholder="Enter email"/>
                    <div className="error-msg">{this.state.errors['email']}</div>
                </Form.Group>
                <div className="position-relative">
                {this.state.errors["response"] && <div className="error-msg"> Sorry! This email ID is not registered. Please check the email ID and try again.</div>}
                  </div>
            </div>
            <Form.Group className="mb-3">
                <Button buttonStyle={this.state.isLoading ? "red-btn btn-full-size mr-top-50 loading-btn-disable" : "red-btn btn-full-size mr-top-50 loading-button"} type="button" onClick={this.getCode} loading={this.state.isLoading}>
                {this.state.isLoading && <Loading/>}
                {!this.state.isLoading && `GET CODE`}
                </Button>
            </Form.Group>
            </Form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.webSiteUser.user,
  type: state.webSiteUser.type,
})
const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  setType: type => dispatch(setType(type)),
  setCurrentComponent: currentComponent => dispatch(setCurrentComponent(currentComponent)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)