import React from "react";
import footer_logo from '../images/cat/logo.png';
import { NavLink } from "react-router-dom";
import location_icon from '../images/cat/location-icon.svg';
import fb_icon from '../assets/fb.png';
import inst_icon from '../assets/inst.png';
import linnk_icon from '../assets/linnk.png';
import tw_icon from '../assets/tw.png';
import call_icon from '../images/cat/call-icon.svg';
import msg_icon from '../images/cat/mail-icon.svg';

function Footer(props) {
  const redirectTo = (url)=>{
                   //   alert(url);
                window.open(url, "_blank");
  }
  return (
    <div className="full-size">
      <div className="footer-outer-block">
      <div className="footer-container">
        <section className="footer-subscription">
            <div className="footer-logo">
              <NavLink onClick={()=> window.scrollTo(0, 0)} to="/" className="banner-block">
                <img src={footer_logo} alt="banner-icon" className="banner-logo-img"></img>
              </NavLink>
            </div>
            <div className="footer-menu-block">
              <div className="menu-terms-block">
                <div className="menu-list">
                  <NavLink onClick={()=> window.scrollTo(0, 0)} to="/" exact className="ft-menu-items">For Institute</NavLink>
                  {/* <NavLink onClick={()=> window.scrollTo(0, 0)} to="/Pricing" className="ft-menu-items">Pricing</NavLink> */}
                  <div className="ft-menu-items" onClick={props.redirectToMYT}>For Teacher</div>
                  <NavLink onClick={()=> window.scrollTo(0, 0)} to="/ForStudents" className="ft-menu-items">For Student</NavLink>
                  <NavLink onClick={()=> window.scrollTo(0, 0)} to="/ContactUs" className="ft-menu-items">Contact us</NavLink>
                  <NavLink onClick={()=> window.scrollTo(0, 0)} to="/TermsOfService" className="ft-menu-items">Terms of Service</NavLink>
                  <NavLink onClick={()=> window.scrollTo(0, 0)} to="/PrivacyPolicy" className="ft-menu-items">Privacy Policy</NavLink>
                </div>
                
                
                <div className="footer-menu-address">
                  <div className="ft-menu-items"><div className="address-icon">
                            <img src={location_icon} alt=""  className="location-img"></img>
                        </div>721, 7th Floor,<br/> East Wing West Side,<br/> Marigold Premises,<br/> Kalyani Nagar,<br/> Pune 411014</div>
                  
                  
                </div>
                <div className="footer-icon-out">
                    <div className="ft-menu-items icon-call-msg">
                      <span><img src={call_icon} className="call-img" alt="fb"/></span>+ 91 7378 888467</div>
                      <div className="ft-menu-items icon-call-msg">
                        <span><img src={msg_icon} className="mail-img" alt="fb"/></span>
                        <a href="mailto:hello@eduracle.com">hello@eduracle.com</a>
                    </div>
                    <div className="footer-icon">
                      <a className="" href="/" onClick={()=>redirectTo('https://www.facebook.com/eduracle')}><img src={fb_icon} alt="fb"/></a>
                      <a href="/" onClick={()=>redirectTo('https://www.linkedin.com/company/eduracle/')}> <img src={linnk_icon} alt="fb"/></a>
                      <a href="/" onClick={()=>redirectTo('https://www.instagram.com/eduracleofficial/')}><img src={inst_icon} alt="fb"/></a>
                      <a href="/" onClick={()=>redirectTo('https://twitter.com/eduracle/')}><img src={tw_icon} alt="fb"/></a>
                    </div>
                </div>
              </div>
              
            </div>
        </section>
      </div>
    </div>
      <section className="bottom-footer">
        <div className="copy-right-footer">
            Copyright 2022 ©Eduracle. All rights reserved.
        </div>
      </section>
    </div>
  );
}

export default Footer;
